import { defineStore } from 'pinia'

export const useDeviceStore = defineStore({
    id: 'device',
    state: () => ({
        ordersHistory: {
            avcs: [],
            avcs1: [{name: 'DE110000', expiryDate:"2024-11-31"}, {name: 'DK1NORSO', expiryDate:"2024-11-31"}, {name: 'IT100340', expiryDate:"2024-11-31"}, {name: 'FI59S191', expiryDate:"2024-11-31"}, {name: 'FI4EIHMN', expiryDate:"2024-11-31"} ],
            adp: [],
            enp: [],
            primar: []
        }
    }),
    actions: {
        addOrdersHistoryAVCS(val) {
            this.ordersHistory.avcs = val
        },
        reset() {
        }
    },
    persist: false
})
