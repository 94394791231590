<template>
  <div>
    <b-sidebar width="500px" id="qr-sidebar" shadow>
      <q-r-scanner></q-r-scanner>
    </b-sidebar>

    <q-r-details :qr="qrcode"></q-r-details>
  </div>
</template>

<script>
import QRDetails from "@/components/qr/QRDetails";
import QRScanner from "@/components/qr/QREngine.vue";
import {eventBus} from "@/main";
import store from "@/store";
export default {
  name: "QRWindow",
  components: {
    QRDetails, QRScanner
  },
  data(){
    return {
      qrcode: null,
    }
  },
  methods: {

  },
  mounted() {
    eventBus.$off("scan-success");
    eventBus.$on('scan-success', (result) => {
      this.$root.$emit('bv::toggle::collapse', 'qr-sidebar');
      console.log(result)

      this.qrcode = JSON.parse(result.decodedText)

      // if (this.qrcode.name === '1') {
      //   this.qrcode = require("@/data/ship1.json")
      // }
      //
      // if (this.qrcode.name === '2') {
      //   this.qrcode = require("@/data/ship2.json")
      // }
      //
      // if (this.qrcode.name === '3') {
      //   this.qrcode = require("@/data/ship3.json")
      // }
      //
      // if (this.qrcode.name === '4') {
      //   this.qrcode = require("@/data/ship4.json")
      // }
      //
      // if (this.qrcode.name === '5') {
      //   this.qrcode = require("@/data/ship5.json")
      // }


      store.commit("fickle/setQRInfo", this.qrcode)
      setTimeout(() => {
        this.$root.$emit('bv::toggle::collapse', 'qr-details')
      }, 1000)
    })

    eventBus.$off("start-scan-qr-ecdis");
    eventBus.$on('start-scan-qr-ecdis', () => {
      this.$root.$emit('bv::toggle::collapse', 'qr-sidebar');
      eventBus.$emit('start-qr-scan')
    })
  }
}
</script>

<style scoped>

</style>