<template>
  <div>
    <b-table style="overflow-x: hidden" sticky-header="520px" size="sm" thead-class="d-none" :items="items()" :fields="visible_fields">
      <template v-slot:cell(value)="row">
        <b-row v-if="row.item.name === 'Draught (m)'">
          <b-col cols="3">{{ routesStore.routeSettings.draught }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="0.2" max="25" v-model="routesStore.routeSettings.draught"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Height (m)'">
          <b-col cols="3">{{ routesStore.routeSettings.height }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="0.2" max="50" v-model="routesStore.routeSettings.height"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Radius (m)'">
          <b-col cols="3">{{ routesStore.routeSettings.radius }}</b-col>
          <b-col>
            <b-form-input type="range" min="10" step="10" max="500" v-model="routesStore.routeSettings.radius"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'XTE.L (m)'">
          <b-col cols="3">{{ routesStore.routeSettings.xtel }}</b-col>
          <b-col>
            <b-form-input type="range" min="10" step="10" max="3000" v-model="routesStore.routeSettings.xtel"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'XTE.R (m)'">
          <b-col cols="3">{{ routesStore.routeSettings.xter }}</b-col>
          <b-col>
            <b-form-input type="range" min="10" step="10" max="3000" v-model="routesStore.routeSettings.xter"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Speed (knots)'">
          <b-col cols="3">{{ routesStore.routeSettings.speed }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="1" max="50" v-model="routesStore.routeSettings.speed"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Track ratio'">
          <b-col cols="3">{{ routesStore.routeSettings.trackratio }}</b-col>
          <b-col>
            <b-form-input type="range" min="0.1" step="0.1" max="0.9" v-model="routesStore.routeSettings.trackratio"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Shallow water ratio'">
          <b-col cols="3">{{ routesStore.routeSettings.shallowratio }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="0.5" max="10" v-model="routesStore.routeSettings.shallowratio"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Avoid shallow water strength'">
          <b-col cols="3">{{ routesStore.routeSettings.avoidshallowstrength }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="0.5" max="10"
                          v-model="routesStore.routeSettings.avoidshallowstrength"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Avoid distance (m)'">
          <b-col cols="3">{{ routesStore.routeSettings.avoiddistance }}</b-col>
          <b-col>
            <b-form-input type="range" min="0" step="10" max="3000" v-model="routesStore.routeSettings.avoiddistance"/>
          </b-col>
        </b-row>

        <b-form-input type="date" v-if="row.item.name === 'Start date'" v-model="routesStore.routeSettings.startdate"/>

        <b-form-input type="time" v-if="row.item.name === 'Start time'" v-model="routesStore.routeSettings.starttime"/>

        <b-form-checkbox v-if="row.item.name === 'Allow Suez canal'" v-model="routesStore.routeSettings.usesuez"/>

        <b-form-checkbox v-if="row.item.name === 'Allow Panama canal'" v-model="routesStore.routeSettings.usepanama"/>

        <b-form-checkbox v-if="row.item.name === 'Allow Bering strait'" v-model="routesStore.routeSettings.usebering"/>

        <b-form-checkbox v-if="row.item.name === 'Allow rivers'" v-model="routesStore.routeSettings.userivers"/>

        <b-form-checkbox v-if="row.item.name === 'Recommended tracks'" v-model="routesStore.routeSettings.userecommendedtracks"/>

        <b-form-checkbox v-if="row.item.name === 'General routes only'" v-model="routesStore.routeSettings.usegeneralroutes"/>
      </template>
    </b-table>


<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Draught (m)">-->
<!--      <b-form-spinbutton id="sb-inline-draught" size="sm" v-model="routesStore.routeSettings.draught" min="1"-->
<!--                         step="0.2" max="25" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Height (m)">-->
<!--      <b-form-spinbutton id="sb-inline-height" size="sm" v-model="routesStore.routeSettings.height" min="1" step="0.2"-->
<!--                         max="50" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Radius (m)">-->
<!--      <b-form-spinbutton id="sb-inline-radius" size="sm" v-model="routesStore.routeSettings.radius" min="10" step="10"-->
<!--                         max="500" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="XTE.L (m)">-->
<!--      <b-form-spinbutton id="sb-inline-xte-l" size="sm" v-model="routesStore.routeSettings.xtel" min="10" step="10"-->
<!--                         max="3000" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="XTE.R (m)">-->
<!--      <b-form-spinbutton id="sb-inline-xte-r" size="sm" v-model="routesStore.routeSettings.xter" min="10" step="10"-->
<!--                         max="3000" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Speed (knots)">-->
<!--      <b-form-spinbutton id="sb-inline-speed" size="sm" v-model="routesStore.routeSettings.speed" min="1" step="1"-->
<!--                         max="50" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Track ratio">-->
<!--      <b-form-spinbutton id="sb-inline-ratio" size="sm" v-model="routesStore.routeSettings.trackratio" min="0.1"-->
<!--                         step="0.1" max="0.9" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Shallow water ratio">-->
<!--      <b-form-spinbutton id="sb-inline-shallowratio" size="sm" v-model="routesStore.routeSettings.shallowratio"-->
<!--                         min="1" step="0.5" max="10" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Avoid shallow water strength">-->
<!--      <b-form-spinbutton id="sb-inline-shallowstrength" size="sm"-->
<!--                         v-model="routesStore.routeSettings.avoidshallowstrength" min="1" step="0.5" max="10"-->
<!--                         locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Avoid distance">-->
<!--      <b-form-spinbutton id="sb-inline-avoiddistance" size="sm" v-model="routesStore.routeSettings.avoiddistance"-->
<!--                         min="0" step="10" max="3000" locale="en"></b-form-spinbutton>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Start date">-->
<!--      <b-form-datepicker id="sb-inline-date" size="sm" v-model="routesStore.routeSettings.startdate"-->
<!--                         placeholder="Select a date" locale="en"></b-form-datepicker>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Start time">-->
<!--      <b-form-timepicker id="sb-inline-time" :hour12=false size="sm" v-model="routesStore.routeSettings.starttime"-->
<!--                         placeholder="Select a time" locale="en"></b-form-timepicker>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="10" label-size="sm" label="Use recommended tracks">-->
<!--      <b-form-checkbox id="sb-inline-use-recommended" size="sm"-->
<!--                       v-model="routesStore.routeSettings.userecommendedtracks" locale="en"></b-form-checkbox>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="10" label-size="sm" label="Allow Suez canal">-->
<!--      <b-form-checkbox id="sb-inline-use-suez" size="sm"-->
<!--                       v-model="routesStore.routeSettings.usesuez" locale="en"></b-form-checkbox>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="10" label-size="sm" label="Allow Panama canal">-->
<!--      <b-form-checkbox id="sb-inline-use-panama" size="sm"-->
<!--                       v-model="routesStore.routeSettings.usepanama" locale="en"></b-form-checkbox>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="10" label-size="sm" label="Allow Bering strait">-->
<!--      <b-form-checkbox id="sb-inline-use-bering" size="sm"-->
<!--                       v-model="routesStore.routeSettings.usebering" locale="en"></b-form-checkbox>-->
<!--    </b-form-group>-->

<!--    <b-form-group class="mb-0" label-cols="10" label-size="sm" label="Allow rivers">-->
<!--      <b-form-checkbox id="sb-inline-use-rivers" size="sm"-->
<!--                       v-model="routesStore.routeSettings.userivers" locale="en"></b-form-checkbox>-->
<!--    </b-form-group>-->


  </div>
</template>

<script>
import {eventBus} from "@/main";
import {useRoutesStore} from "@/stores/routes";
import {mapStores} from "pinia";
import {debounce} from 'vue2-debounce'
import dayjs from "dayjs";

export default {
  data() {
    return {
      debounceDepth: {
        isDebounced: false,
      },
    }
  },
  watch: {
    'routesStore.routeSettings.draught'(newVal) {
      if (!this.debounceDepth.isDebounced) {
        this.debounceDepth.isDebounced = true
        debounce(val => this.redrawNoGo(val), 2000)(newVal)
      }
    },
    'routesStore.routeSettings.xtel'() {
      eventBus.$emit('xte-changed')
    },
    'routesStore.routeSettings.xter'() {
      eventBus.$emit('xte-changed')
    },
    'routesStore.routeSettings.startdate'() {
      eventBus.$emit('route-datetime-changed')
    },
    'routesStore.routeSettings.starttime'() {
      eventBus.$emit('route-datetime-changed')
    },
  },
  mounted() {
    let now = dayjs()
    // console.log("nn24", now)
    // console.log("nn24 1", dayjs(now).format("YYYY-MM-DD"))
    // console.log("nn24 2", dayjs(now).format("HH:mm:ss"))
    // console.log("nn24 3", this.routesStore.routeSettings.startdate)
    // console.log("nn24 4", this.routesStore.routeSettings.starttime)
    this.routesStore.addRouteDate(dayjs(now).format("YYYY-MM-DD"))
    this.routesStore.addRouteTime(dayjs(now).format("HH:mm:ss"))
  },
  beforeDestroy() {
  },
  computed: {
    ...mapStores(useRoutesStore),
    visible_fields() {
      return [
        {key: 'name', label: 'Settings', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'value', label: 'Values', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    items() {
      return [{name: 'Draught (m)', value: this.routesStore.routeSettings.draught},
        {name: 'Height (m)', value: this.routesStore.routeSettings.height},
        {name: 'Radius (m)', value: this.routesStore.routeSettings.radius},
        {name: 'XTE.L (m)', value: this.routesStore.routeSettings.xtel},
        {name: 'XTE.R (m)', value: this.routesStore.routeSettings.xter},
        {name: 'Speed (knots)', value: this.routesStore.routeSettings.speed},
        {name: 'Track ratio', value: this.routesStore.routeSettings.trackratio},
        {name: 'Shallow water ratio', value: this.routesStore.routeSettings.shallowratio},
        {name: 'Avoid shallow water strength', value: this.routesStore.routeSettings.avoidshallowstrength},
        {name: 'Avoid distance (m)', value: this.routesStore.routeSettings.avoiddistance},
        {name: 'Start date', value: this.routesStore.routeSettings.startdate},
        {name: 'Start time', value: this.routesStore.routeSettings.starttime},
        {name: 'Allow Suez canal', value: this.routesStore.routeSettings.usesuez},
        {name: 'Allow Panama canal', value: this.routesStore.routeSettings.usepanama},
        {name: 'Allow Bering strait', value: this.routesStore.routeSettings.usebering},
        {name: 'Allow rivers', value: this.routesStore.routeSettings.userivers},
        {name: 'Recommended tracks', value: this.routesStore.routeSettings.userecommendedtracks},
        {name: 'General routes only', value: this.routesStore.routeSettings.usegeneralroutes}]

    },
    redrawNoGo(val) {
      this.debounceDepth.isDebounced = false
      eventBus.$emit('change-nogo');
    }
  }
}
</script>
