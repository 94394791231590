import {RouteBaseWPLeaf} from "@/my-leaf/routes/route-leaf-base-wp";
import L from "leaflet";
import {eventBus} from "@/main";
import store from "@/store";
import {myAxios} from "@/services/myAxios";
import {prepareError, showError} from "@/my-leaf/routes/route-errors";
// import moment from "moment";
// import {useRoutesStore} from "@/stores/routes";
import {keepRoute} from "@/helpers/lsHelpers";

import * as dayjs from 'dayjs/dayjs.min'
import {getDepth, showInfo} from "@/my-leaf/routes/route-utils";
import routeSettings from "@/views/routes/RouteSettings.vue";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const portsJSON = require("@/data/ports.json")

// let nogoList = null

export class RouteBaseRoutingLeaf extends RouteBaseWPLeaf {
    // routesStore = null
    portsMarkers = []
    portsLayer = null
    nogoList = null
    cancel_auto_route = false
    is_autorouting = false
    autorouting_points = 0
    routeRequestPoints = null
    startSegment = -1
    finishSegment = -1

    constructor() {
        super();
    }

    async initbaserouting(el) {
        super.initbasewp(el);

        // this.routesStore = useRoutesStore()

        this.nogoList = new L.FeatureGroup();
        this.map.addLayer(this.nogoList);

        let options = {
            position: 'topleft',
            draw: {
                polyline: false,
                circle: false,
                polygon: false,
                marker: false,
                rectangle: {shapeOptions: {color: '#f357a1', weight: 1}},
                circlemarker: false
            },
            edit: {
                featureGroup: this.nogoList,
                remove: true
            }
        };

        this.drawControl = new L.Control.Draw(options)
        // this.map.on('draw:created', function (e) {
        //     let type = e.layerType,
        //         layer = e.layer;
        //
        //     console.log("draw created")
        //     this.nogoList.addLayer(layer);
        // });

        this.map.on('draw:created', (e) => {
            let type = e.layerType,
                layer = e.layer;

            console.log("draw created")
            this.nogoList.addLayer(layer);
        });

        // eventBus.$off('clear-ports')
        // eventBus.$on('clear-ports', () => {
        //     this.clearPorts()
        // })

        eventBus.$off('show-ports-on-map')
        eventBus.$on('show-ports-on-map', () => {
            this.drawPorts()
        })

        eventBus.$off('check-safety')
        eventBus.$on('check-safety', () => {
            // store.commit("routes/setMovingWP", store.state.routes.movingWP)
            this.checkSafety()
        })

        eventBus.$off('clear-nogo-draw')
        eventBus.$on('clear-nogo-draw', () => {
            this.nogoList.clearLayers()
        })

        eventBus.$off('save-nogo')
        eventBus.$on('save-nogo', () => {
            let resctrictedZones = [];
            this.nogoList.getLayers().forEach(area => {
                let bounds = area.getBounds()
                let restZone = {
                    "south": bounds.getSouth(),
                    "north": bounds.getNorth(),
                    "west": bounds.getWest(),
                    "east": bounds.getEast()
                }
                resctrictedZones.push(restZone)
            })
            console.log("ggg", resctrictedZones)
            eventBus.$emit('enter-zone-name', {zones: resctrictedZones, zoneslayer: this.nogoList})


        })

        eventBus.$off('show-dangers-in-wp')
        eventBus.$on('show-dangers-in-wp', (id) => {

            this.dangerMarkers.clearLayers()
            if (id !== -1) {
                store.state.routes.dangers.forEach(obj => {
                    if (obj.isDanger && (id === obj.legIndex || id === obj.legIndex - 1 || id === obj.legIndex + 1))
                        this.setDangerMarkers(obj.points, 'danger');
                })
            }
        })


        // eventBus.$off('toggle-mode-after-auto')
        // eventBus.$on('toggle-mode-after-auto', () => {
        //     alert(3)
        //     this.is_autorouting = false
        //
        //     this.startSegment = -1
        //     this.finishSegment = -1
        //
        //     this.autorouting_points = 0
        //     let autoWPMarkers = this.wpMarkers
        //     this.removeRoute()
        //     store.commit("routes/resetStorage")
        //     autoWPMarkers.forEach(wp => {
        //         this.addWP(wp.getLatLng())
        //     })
        //     this.drawLegs()
        //
        //     this.map.removeControl(this.autorouteControl)
        //     this.map.removeControl(this.upButton)
        //     this.map.removeControl(this.downButton)
        //
        //     this.autorouteControl.addTo(this.map)
        //     this.downButton.addTo(this.map)
        //     this.upButton.addTo(this.map)
        // })

    }

    aaa2(e) {

    }

    checkSafety = () => {
        let pts = []

        this.wpMarkers.forEach(wp => {
            pts.push({
                lat: wp.getLatLng().lat,
                lon: wp.getLatLng().lng,
                radius: this.routesStore.routeSettings.radius,
                xteLeft: this.routesStore.routeSettings.xtel,
                xteRight: this.routesStore.routeSettings.xter
            })
        })

        this.startRouteCalculation(pts, [], false)
    }

    calculateRoute = (wpIndexStart = -1, wpIndexFinish = -1) => {


        this.startSegment = wpIndexStart
        this.finishSegment = wpIndexFinish

        console.log("a1")

        let resctrictedZones = [];

        store.state.fickle.routes.nogoZones.forEach(name => {
            console.log("jjj", name)
            this.routesStore.nogoZones.forEach(item => {
                if (item.name === name) {
                    item.zones.forEach(zone => {
                        let restZone = {
                            "south": zone.south,
                            "north": zone.north,
                            "west": zone.west,
                            "east": zone.east
                        }
                        resctrictedZones.push(restZone)

                    })
                }
            })
        })

        // this.nogoList.getLayers().forEach(area => {
        //     let bounds = area.getBounds()
        //     let restZone = {
        //         "south": bounds.getSouth(),
        //         "north": bounds.getNorth(),
        //         "west": bounds.getWest(),
        //         "east": bounds.getEast()
        //     }
        //     resctrictedZones.push(restZone)
        // })

        let startIndex = 0
        let finishIndex = this.wpMarkers.length - 1
        let midIndex = -1

        console.log("a2")

        if (wpIndexFinish - wpIndexStart === 1) {
            startIndex = wpIndexStart
            finishIndex = wpIndexFinish
        }

        if (wpIndexFinish - wpIndexStart === 2) {
            startIndex = wpIndexStart
            finishIndex = wpIndexFinish
            midIndex = wpIndexFinish - 1
        }

        let group = new L.featureGroup([this.wpMarkers[startIndex], this.wpMarkers[finishIndex]]);
        this.map.fitBounds(group.getBounds())

        this.drawDebugLine(this.wpMarkers[startIndex], this.wpMarkers[finishIndex])

        console.log("a3")

        let pts = []
        pts.push({
            lat: this.wpMarkers[startIndex].getLatLng().lat,
            lon: this.wpMarkers[startIndex].getLatLng().lng,
            radius: this.routesStore.routeSettings.radius,
            xteLeft: this.routesStore.routeSettings.xtel,
            xteRight: this.routesStore.routeSettings.xter
        })

        console.log("a4")
        console.log(midIndex)

        if (finishIndex === startIndex + 2 && midIndex !== -1) {
            pts.push({
                lat: this.wpMarkers[midIndex].getLatLng().lat,
                lon: this.wpMarkers[midIndex].getLatLng().lng,
                radius: this.routesStore.routeSettings.radius,
                xteLeft: this.routesStore.routeSettings.xtel,
                xteRight: this.routesStore.routeSettings.xter
            })
        }

        console.log("a5")

        pts.push({
            lat: this.wpMarkers[finishIndex].getLatLng().lat,
            lon: this.wpMarkers[finishIndex].getLatLng().lng,
            radius: this.routesStore.routeSettings.radius,
            xteLeft: this.routesStore.routeSettings.xtel,
            xteRight: this.routesStore.routeSettings.xter
        })

        console.log("a7")

        // alert(pts.length)

        if (pts.length === 3) {
            console.log("qqq", "next2")
            this.startRouteCalculationMiddle(pts, resctrictedZones)
        } else {
            this.startRouteCalculation(pts, resctrictedZones)
        }
    }

    async getRoute(routeId) {
        // get route progress and state
        return new Promise(resolve => {
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {

                    store.commit("routes/setProgress", resp.data.progress)

                    if (resp.data.status === 'calculating' && this.cancel_auto_route) {
                        store.commit("routes/setCalculatingStatus", false)
                        this.restoreContextAfterAuto()
                        this.cancel_auto_route = false
                        myAxios.delete("api/v1/route/" + routeId).then(resp => {
                        })
                        resolve(resp.data)
                        // return
                    }

                    if (resp.data.status === 'calculating' && resp.data.restoreRoutePoints.length > 0) {
                        this.routesStore.addRouteGeneral(resp.data.restoreRoutePoints)
                        this.drawGeneralRoute()
                    }

                    if (resp.data.status === 'calculating' && resp.data.routeLegs.length > 0) {
                        this.routesStore.addRouteLegs(resp.data.routeLegs)
                        this.drawLegsByProgress()
                    }

                    if (resp.data.status === 'done') {
                        // alert(1)
                        store.commit("routes/setCalculatingStatus", false)
                        if (resp.data.isSuccess)
                            this.drawRoute(resp.data)
                        else
                            this.showError(resp.data)

                        this.restoreContextAfterAuto()
                        resolve(resp.data)
                        // alert(0)
                        // return
                    }

                    if (resp.data.status === 'calculating' && !this.cancel_auto_route) {
                        setTimeout(() => {
                            resolve(this.getRoute(routeId))
                        }, 1500);
                    }
                })
        })
    }

    calculateByPass(wpID1, wpID2) {
        let pts = []

        store.commit("routes/useBypass", [wpID1, wpID2])

        pts.push({
            lat: store.state.routes.route[wpID1].lat,
            lon: store.state.routes.route[wpID1].lon,
            radius: this.routesStore.routeSettings.radius,
            xteLeft: this.routesStore.routeSettings.xtel,
            xteRight: this.routesStore.routeSettings.xter
        })

        pts.push({
            lat: store.state.routes.route[wpID2].lat,
            lon: store.state.routes.route[wpID2].lon,
            radius: this.routesStore.routeSettings.radius,
            xteLeft: this.routesStore.routeSettings.xtel,
            xteRight: this.routesStore.routeSettings.xter
        })

        this.startRouteCalculation(pts, [], true, false, true)
    }


    startRouteCalculation(pts, zones, isAutoRoute = true, isGlobalRoute = false, isBypass = false) {
        this.routeRequestPoints = pts
        store.commit("routes/setRoutePointsBackup")

        this.setTileLayerWithDraught();
        const draught = this.routesStore.routeSettings.draught;
        const height = this.routesStore.routeSettings.height
        const radius = this.routesStore.routeSettings.radius
        const xte_l = this.routesStore.routeSettings.xtel
        const xte_r = this.routesStore.routeSettings.xter
        const speed = this.routesStore.routeSettings.speed
        const recommended = this.routesStore.routeSettings.userecommendedtracks
        const generalroutesonly = isBypass ? false : this.routesStore.routeSettings.usegeneralroutes
        const ratio = this.routesStore.routeSettings.trackratio
        const shallowratio = this.routesStore.routeSettings.shallowratio
        const shallowstrength = this.routesStore.routeSettings.avoidshallowstrength
        const avoiddistance = this.routesStore.routeSettings.avoiddistance
        const usesuez = this.routesStore.routeSettings.usesuez
        const usepanama = this.routesStore.routeSettings.usepanama
        const usebering = this.routesStore.routeSettings.usebering
        const userivers = this.routesStore.routeSettings.userivers
        // const draught = document.getElementById('sb-inline-draught').value;
        // const height = document.getElementById('sb-inline-height').value;
        // const radius = document.getElementById('sb-inline-radius').value;
        // const xte_l = document.getElementById('sb-inline-xte-l').value;
        // const xte_r = document.getElementById('sb-inline-xte-r').value;
        // const speed = document.getElementById('sb-inline-speed').value;
        // const recommended = document.getElementById('sb-inline-use-recommended').checked;
        // const ratio = document.getElementById('sb-inline-ratio').value;
        // const shallowratio = document.getElementById('sb-inline-shallowratio').value;
        // const shallowstrength = document.getElementById('sb-inline-shallowstrength').value;
        // const avoiddistance = document.getElementById('sb-inline-avoiddistance').value;
        // const usesuez = document.getElementById('sb-inline-use-suez').checked;
        // const usepanama = document.getElementById('sb-inline-use-panama').checked;
        // const usebering = document.getElementById('sb-inline-use-bering').checked;
        // const userivers = document.getElementById('sb-inline-use-rivers').checked;

        store.commit("routes/resetStorage")

        this.removeRoute()

        let nogoObjects = []
        store.state.fickle.routes.nogoObjects.forEach(item => {
            nogoObjects.push(item)
        })
        let nogoCategories = []
        store.state.fickle.routes.nogoCategories.forEach(item => {
            nogoCategories.push(item)
        })

        // console.log("jjj1", zones)

        // alert(12)

        // let post_text =
        //     {
        //         "useAutoRoute": true,
        //         "useRecomendedTracks": true,
        //         "resctrictedZones": [],
        //         "speedKnots": "10",
        //         "points": [
        //             {
        //                 "lat": 53.2,
        //                 "lon": 3.2,
        //                 "radius": "120",
        //                 "xteLeft": "100",
        //                 "xteRight": "100"
        //             },
        //             {
        //                 "lat": 51.3,
        //                 "lon": -6.1,
        //                 "radius": "120",
        //                 "xteLeft": "100",
        //                 "xteRight": "100"
        //             }
        //         ],
        //         "depth": "10.0",
        //         "height": "20.0",
        //         "defaultXteLeft": "120",
        //         "defaultXteRight": "120",
        //         "defaultRadius": "120",
        //         "waitSeconds": 0,
        //         "recomendedTrackCost": "0.7",
        //         "shallowWaterCoeff": "5.0",
        //         "shallowWaterCost": "5.0",
        //         "avoidDistance": "120",
        //         "resctrictedIds": [],
        //         "resctrictedCategories": [],
        //         "restoreRoutePoints": [
        //         ]
        //     }

        // let post_text =
        //     {
        //         "useAutoRoute": true,
        //         "useRecomendedTracks": true,
        //         "resctrictedZones": [],
        //         "speedKnots": "10",
        //         "points": [
        //             {
        //                 "lat": 54.87637375866509,
        //                 "lon": 6.886700729075112,
        //                 "radius": "120",
        //                 "xteLeft": "100",
        //                 "xteRight": "100"
        //             },
        //             {
        //                 "lat": 56.42058252358786,
        //                 "lon": 3.997860658776373,
        //                 "radius": "120",
        //                 "xteLeft": "100",
        //                 "xteRight": "100"
        //             }
        //         ],
        //         "depth": "10.0",
        //         "height": "20.0",
        //         "defaultXteLeft": "120",
        //         "defaultXteRight": "120",
        //         "defaultRadius": "120",
        //         "waitSeconds": 0,
        //         "recomendedTrackCost": "0.7",
        //         "shallowWaterCoeff": "5.0",
        //         "shallowWaterCost": "5.0",
        //         "avoidDistance": "120",
        //         "resctrictedIds": [],
        //         "resctrictedCategories": [],
        //         "restoreRoutePoints": [
        //             {
        //                 "lat": 54.87637375866509,
        //                 "lon": 6.886700729075112
        //             },
        //             {
        //                 "lat": 55.30238,
        //                 "lon": 4.2448
        //             },
        //             {
        //                 "lat": 56.42058252358786,
        //                 "lon": 3.997860658776373
        //             }
        //         ]
        //     }


        let gRoute = []
        let start = {}
        let finish = {}
        let points = pts
        let post_text = {}

        let URL = '/api/v1/route'
        if (isGlobalRoute) {

            store.commit("routes/setCalculatingStatus", true)
            URL = '/api/v1/mss_route'
            // alert(1)

            // let gRoute = []
            let portStart = store.state.fickle.routes.globalRoute.ports[0]
            let portFinish = store.state.fickle.routes.globalRoute.ports[1]

            let portStartCoordinates = store.state.fickle.routes.globalRoute.portsCoordinates[0]
            let portFinishCoordinates = store.state.fickle.routes.globalRoute.portsCoordinates[1]

            let southWest = L.latLng(portStartCoordinates.lat, portStartCoordinates.lon),
                northEast = L.latLng(portFinishCoordinates.lat, portFinishCoordinates.lon),
                bounds = L.latLngBounds(southWest, northEast);

            this.map.fitBounds(bounds)

            // store.state.fickle.routes.globalRoute.waypoints.forEach(wp => {
            //     gRoute.push({lat: wp.lat, lon: wp.lon})
            // })
            //
            // start = {
            //     lat: store.state.fickle.routes.globalRoute.waypoints[0].lat,
            //     lon: store.state.fickle.routes.globalRoute.waypoints[0].lon,
            //     radius: "120",
            //     xteLeft: "100",
            //     xteRight: "100"
            // }
            //
            // finish = {
            //     lat: store.state.fickle.routes.globalRoute.waypoints[store.state.fickle.routes.globalRoute.waypoints.length - 1].lat,
            //     lon: store.state.fickle.routes.globalRoute.waypoints[store.state.fickle.routes.globalRoute.waypoints.length - 1].lon,
            //     radius: "120",
            //     xteLeft: "100",
            //     xteRight: "100"
            // }
            //
            // this.routeRequestPoints.push(start)
            // this.routeRequestPoints.push(finish)

            post_text = {
                useAutoRoute: isAutoRoute,
                useRecomendedTracks: recommended,
                resctrictedZones: zones,
                speedKnots: speed,
                // points2: pts,
                points: [
                    // start,
                    // finish
                ],
                from: portStart,
                to: portFinish,
                depth: draught,
                height: height,
                defaultXteLeft: xte_l,
                defaultXteRight: xte_r,
                defaultRadius: radius,
                waitSeconds: 0,
                recomendedTrackCost: ratio,
                shallowWaterCoeff: shallowratio,
                shallowWaterCost: shallowstrength,
                avoidDistance: avoiddistance,
                allowSuezCanal: usesuez,
                allowPanamaCanal: usepanama,
                allowBeringStrait: usebering,
                allowRivers: userivers,
                generalRouteOnly: generalroutesonly,
                resctrictedIds: nogoObjects,
                resctrictedCategories: nogoCategories,
                restoreRoutePoints: gRoute
            }
        } else {
            post_text = {
                useAutoRoute: isAutoRoute,
                useRecomendedTracks: recommended,
                resctrictedZones: zones,
                speedKnots: speed,
                // points2: pts,
                points: pts,
                depth: draught,
                height: height,
                defaultXteLeft: xte_l,
                defaultXteRight: xte_r,
                defaultRadius: radius,
                waitSeconds: 0,
                recomendedTrackCost: ratio,
                shallowWaterCoeff: shallowratio,
                shallowWaterCost: shallowstrength,
                avoidDistance: avoiddistance,
                allowSuezCanal: usesuez,
                allowPanamaCanal: usepanama,
                allowBeringStrait: usebering,
                allowRivers: userivers,
                generalRouteOnly: generalroutesonly,
                resctrictedIds: nogoObjects,
                resctrictedCategories: nogoCategories,
                restoreRoutePoints: []
            }
        }


        // let post_text = {
        //     useAutoRoute: isAutoRoute,
        //     useRecomendedTracks: recommended,
        //     resctrictedZones: zones,
        //     speedKnots: speed,
        //     // points2: pts,
        //     points: [
        //             start,
        //             finish
        //     ],
        //     depth: draught,
        //     height: height,
        //     defaultXteLeft: xte_l,
        //     defaultXteRight: xte_r,
        //     defaultRadius: radius,
        //     waitSeconds: 0,
        //     recomendedTrackCost: ratio,
        //     shallowWaterCoeff: shallowratio,
        //     shallowWaterCost: shallowstrength,
        //     avoidDistance: avoiddistance,
        //     resctrictedIds: nogoObjects,
        //     resctrictedCategories: nogoCategories,
        //     restoreRoutePoints: gRoute
        // }

        navigator.clipboard.writeText(JSON.stringify(post_text))

        myAxios.get("api/v1/route/state").then(resp => {
            myAxios.post(URL, post_text)
                .then(resp => {
                    if (resp.data.errorCode === 0 && resp.data.id) {
                        store.commit("routes/setCalculatingStatus", true)
                        // get route calculation progress and result
                        // this.getRoute(resp.data.id).then(resp => {
                        //     alert("a1" + resp)
                        // })
                        this.getRoute(resp.data.id)
                    } else {
                        alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                    }
                }).catch((error) => {
                    if (isGlobalRoute)
                        alert('Error: (' + error + ') \nCan\'t compute routes by ports at the moment');
                    else
                        alert('Error: (' + error.status + ') \nCan\'t compute routes at the moment');

                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
            })
        }).catch(error => {
            if (error.response.status === 503) {
                alert("No free slots for route calculating, try later")
                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
                return
            } else {
                alert("ERROR CHECK: 1024")
            }

        })
    }

    async getRouteMiddle(routeId) {
        // get route progress and state
        return new Promise(resolve => {
            console.log("qqq", "next_b8")
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {
                    store.commit("routes/setProgress", resp.data.progress)

                    console.log('nnn', resp.data.status)
                    console.log("qqq", "next_b81")
                    console.log("qqq", resp.data.status)

                    if (resp.data.status === 'calculating' && this.cancel_auto_route) {
                        console.log("qqq", "next_b82")
                        console.log('nnn', resp.data.status + " cancel")
                        store.commit("routes/setCalculatingStatus", false)
                        this.restoreContextAfterAuto()
                        this.cancel_auto_route = false
                        myAxios.delete("api/v1/route/" + routeId).then(resp => {
                        })
                        resolve(resp.data)
                        // return
                    }

                    if (resp.data.status === 'done') {
                        console.log("qqq", "next_b84")
                        console.log('nnn', resp.data.status + " done")
                        store.commit("routes/setCalculatingStatus", false)
                        // if (resp.data.isSuccess)
                        //     this.drawRoute(resp.data)
                        // else
                        //     this.showError(resp.data)
                        //
                        // this.restoreContextAfterAuto()
                        resolve(resp.data)
                        // alert(0)
                        // return
                    }

                    if (resp.data.status === 'calculating') {
                        console.log('nnn', resp.data.status + " timeout")
                        setTimeout(() => {
                            resolve(this.getRouteMiddle(routeId))
                        }, 1500);
                    }
                })
        })
    }

    async getRouteMiddle2(routeId) {
        // get route progress and state
        return new Promise(resolve => {
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {
                    store.commit("routes/setProgress", resp.data.progress)

                    console.log('nnn', resp.data.status)

                    if (resp.data.status === 'calculating' && this.cancel_auto_route) {
                        console.log('nnn', resp.data.status + " cancel")
                        store.commit("routes/setCalculatingStatus", false)
                        this.restoreContextAfterAuto()
                        this.cancel_auto_route = false
                        myAxios.delete("api/v1/route/" + routeId).then(resp => {
                        })
                        resolve(resp.data)
                        // return
                    }

                    if (resp.data.status === 'done') {
                        console.log('nnn', resp.data.status + " done")
                        store.commit("routes/setCalculatingStatus", false)
                        if (!resp.data.isSuccess)
                            this.showError(resp.data)
                        //     this.drawRoute(resp.data)
                        // else
                        //     this.showError(resp.data)

                        // this.restoreContextAfterAuto()
                        resolve(resp.data)
                        // alert(0)
                        // return
                    }

                    if (resp.data.status === 'calculating') {
                        console.log('nnn', resp.data.status + " timeout")
                        setTimeout(() => {
                            resolve(this.getRouteMiddle2(routeId))
                        }, 1500);
                    }
                })
        })
    }

    startRouteCalculationMiddle(pts, zones, isAutoRoute = true) {
        alert('m2')
        console.log("qqq", "next_a2")

        let pts1 = [pts[0], pts[1]]
        let pts2 = [pts[1], pts[2]]

        this.routeRequestPoints = pts
        store.commit("routes/setRoutePointsBackup")

        console.log("qqq", "next_a4")

        // alert(this.startSegment + " -2- " + this.finishSegment)

        this.setTileLayerWithDraught();
        const draught = document.getElementById('sb-inline-draught').value;
        const height = document.getElementById('sb-inline-height').value;
        const radius = document.getElementById('sb-inline-radius').value;
        const xte_l = document.getElementById('sb-inline-xte-l').value;
        const xte_r = document.getElementById('sb-inline-xte-r').value;
        const speed = document.getElementById('sb-inline-speed').value;
        const recommended = document.getElementById('sb-inline-use-recommended').checked;
        const ratio = document.getElementById('sb-inline-ratio').value;
        const shallowratio = document.getElementById('sb-inline-shallowratio').value;
        const shallowstrength = document.getElementById('sb-inline-shallowstrength').value;
        const avoiddistance = document.getElementById('sb-inline-avoiddistance').value;
        const usesuez = document.getElementById('sb-inline-use-suez').checked;
        const usepanama = document.getElementById('sb-inline-use-panama').checked;
        const usebering = document.getElementById('sb-inline-use-bering').checked;
        const userivers = document.getElementById('sb-inline-use-rivers').checked;

        console.log("qqq", "next_a6")

        store.commit("routes/resetStorage")

        let a = this.startSegment, b = this.finishSegment
        this.removeRoute()
        this.startSegment = a
        this.finishSegment = b

        console.log("qqq", "next_a8")

        // alert(this.startSegment + " -3- " + this.finishSegment)

        let post_text1 = {
            useAutoRoute: isAutoRoute,
            useRecomendedTracks: recommended,
            resctrictedZones: zones,
            speedKnots: speed,
            points: pts1,
            depth: draught,
            height: height,
            defaultXteLeft: xte_l,
            defaultXteRight: xte_r,
            defaultRadius: radius,
            waitSeconds: 0,
            recomendedTrackCost: ratio,
            shallowWaterCoeff: shallowratio,
            shallowWaterCost: shallowstrength,
            avoidDistance: avoiddistance
        }

        let post_text2 = {
            useAutoRoute: isAutoRoute,
            useRecomendedTracks: recommended,
            resctrictedZones: zones,
            speedKnots: speed,
            points: pts2,
            depth: draught,
            height: height,
            defaultXteLeft: xte_l,
            defaultXteRight: xte_r,
            defaultRadius: radius,
            waitSeconds: 0,
            recomendedTrackCost: ratio,
            shallowWaterCoeff: shallowratio,
            shallowWaterCost: shallowstrength,
            avoidDistance: avoiddistance
        }

        // navigator.clipboard.writeText(JSON.stringify(post_text))

        console.log("qqq", "next_a12")

        myAxios.get("api/v1/route/state").then(resp => {
            console.log("qqq", "next_a14")
            myAxios.post('/api/v1/route', post_text1)
                .then(resp => {
                    console.log("qqq", "next_a24")
                    if (resp.data.errorCode === 0 && resp.data.id) {
                        store.commit("routes/setCalculatingStatus", true)
                        // get route calculation progress and result
                        // this.getRoute(resp.data.id).then(resp => {
                        //     alert("a1" + resp)
                        // })
                        // alert(this.startSegment + " -4- " + this.finishSegment)

                        this.getRouteMiddle(resp.data.id).then(resp => {
                            console.log('qqq', 'next_4_4_4')

                            store.commit("routes/setRouteSegment1Points", resp)

                            console.log("qqq", "next4_1")

                            myAxios.post('/api/v1/route', post_text2)
                                .then(resp => {

                                    // alert(this.startSegment + " -5- " + this.finishSegment)

                                    if (resp.data.errorCode === 0 && resp.data.id) {
                                        console.log("qqq", "next4_2")
                                        store.commit("routes/setCalculatingStatus", true)
                                        this.getRouteMiddle2(resp.data.id).then(resp => {

                                            // alert(this.startSegment + " -6- " + this.finishSegment)

                                            console.log("qqq", "next4_4")
                                            console.log('nnn', 'next end')
                                            store.commit("routes/setRouteSegment2Points", resp)
                                            // this.drawRoute(resp.data)


                                            // alert(this.startSegment + " -7- " + this.finishSegment)

                                            let ptsall = store.state.routes.routePrevious
                                            let ptseg1 = store.state.routes.segment1Points.routePoints
                                            let ptseg2 = store.state.routes.segment2Points.routePoints
                                            this.removeRoute()
                                            this.removeRouteWithDebug()


                                            console.log("qqq", "next4")

                                            // alert(this.startSegment + " -8- " + this.finishSegment)

                                            console.log("eee", this.startSegment, this.finishSegment)
                                            console.log("eee1", a, b)

                                            ptsall.forEach((wp, i) => {
                                                if (i < a) {
                                                    this.addWP(wp, -1, false)
                                                }
                                            })

                                            ptseg1.forEach((wp, i) => {
                                                this.addWP(wp, -1, false)
                                            })

                                            ptseg2.forEach((wp, i) => {
                                                if (i > 0)
                                                    this.addWP(wp, -1, false)
                                            })

                                            ptsall.forEach((wp, i) => {
                                                if (i > b) {
                                                    this.addWP(wp, -1, false)
                                                }
                                            })


                                            this.drawLegs()
                                            this.checkSafety()
                                            this.restoreContextAfterAuto()

                                        })
                                    } else {
                                        alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                                    }
                                })
                        })
                    } else {
                        alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                    }
                })
        }).catch(error => {
            if (error.response.status === 503) {
                alert("No free slots for route calculating, try later")
                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
                return
            } else {
                alert("ERROR CHECK: 1024")
            }

        })
    }

    restoreContextAfterAuto() {
        // alert(1)
        this.is_autorouting = false
        this.autorouting_points = 0

        this.startSegment = -1
        this.finishSegment = -1

        // let autoWPMarkers = this.wpMarkers
        // this.removeRoute()
        // store.commit("routes/resetStorage")
        //
        // autoWPMarkers.forEach(wp => {
        //     this.addWP(wp.getLatLng())
        // })
        // this.drawLegs()


        // console.log("aaa2", this.autorouteControl)
        // console.log("aaa", this.upButton)
        // console.log("aaa", this.downButton)

        eventBus.$emit('clear-ports')

        this.map.removeControl(this.clearControl)
        this.map.removeControl(this.safetyControl)
        this.map.removeControl(this.safetyWPControl)
        this.map.removeControl(this.recalculateControl)
        this.map.removeControl(this.recalculateWPControl)
        // this.map.removeControl(this.autorouteControl)
        this.map.removeControl(this.wpControl)
        this.map.removeControl(this.moveControl)
        // this.map.removeControl(this.downButton)

        this.clearControl.addTo(this.map)
        this.safetyControl.addTo(this.map)
        this.safetyWPControl.addTo(this.map)
        this.recalculateControl.addTo(this.map)
        this.recalculateWPControl.addTo(this.map)
        // this.autorouteControl.addTo(this.map)
        this.wpControl.addTo(this.map)

        console.log("eee", this.movingWP)

        let movingWPId = store.state.routes.movingWP
        if (movingWPId !== -1) {
            eventBus.$emit('click-wp', movingWPId)
            this.map.panTo(this.wpMarkers[movingWPId].getLatLng())
            eventBus.$emit('show-dangers-in-wp', store.state.routes.movingWP)
        }


        // this.downButton.addTo(this.map)
        // this.upButton.addTo(this.map)
    }

    drawRoute(resp1, resp2 = null) {
        // const pts = resp.routePoints.slice(1, resp.routePoints.length - 1);

        // let a1 = [1,2,3,4,5,6,7,8]
        // let a2 = [10,20,30,40,50,60,70]
        //
        // a1.splice(3, 2, ...a2)
        // console.log("aaaa ____  ", a1)

        let resp = resp1
        let pts

        if (!store.state.routes.prev.useBypass) {
            store.commit("routes/storeBypassResp", resp)
            pts = resp.routePoints
        }

        // pts = resp.routePoints

        if (store.state.routes.prev.useBypass) {

            let id1 = store.state.routes.prev.wpIDs[0]
            let id2 = store.state.routes.prev.wpIDs[1]
            let prevResp = store.state.routes.prev.resp
            let newResp = resp

            let prevRoutePoints = prevResp['routePoints']
            let newRoutePoints = newResp['routePoints']

            let prevRoutePointsInfos = prevResp['routePointInfos']
            let newRoutePointsInfos = newResp['routePointInfos']

            let prevRouteLegs = prevResp['routeLegs']
            let newRouteLegs = newResp['routeLegs']

            // resp = prevResp
            // pts = prevResp

            // alert(id1 + " " + id2)
            // a1.splice(3, 2, ...a2)
            prevRoutePoints.splice(id1, id2-id1, ...newRoutePoints)
            prevResp['routePoints'] = prevRoutePoints

            prevRoutePointsInfos.splice(id1, id2-id1, ...newRoutePointsInfos)
            prevResp['routePointInfos'] = prevRoutePointsInfos

            prevRouteLegs.splice(id1, id2-id1, ...newRouteLegs)
            prevRouteLegs['routeLegs'] = prevRouteLegs

            resp = prevResp
            pts = resp.routePoints

            store.commit("routes/clearBypassResp")

        }



        console.log("aaa3", this.startSegment, this.finishSegment)

        store.commit("routes/setRoute", {
            route: [],
            length: '',
            dangers: [],
        })

        store.commit("routes/setRoute", {
            route: pts,
            length: resp.routeLength,
            dangers: resp.dangerObjects,
            info: resp.routePointInfos,
            debug: resp.debugInfo
        })

        this.routesStore.addRoutePoints(pts)
        this.routesStore.addRouteInfo(resp.routePointInfos)
        this.routesStore.addRouteDangerObjects(resp.dangerObjects)
        this.routesStore.addRouteGeneral(resp.restoreRoutePoints)
        this.routesStore.addRouteLegs(resp.routeLegs)

        console.log("eeee1", resp)

        // if (store.state.routes.prev.useBypass) {
        //     pts = store.state.routes.prev.routeWithInfo
        //     store.commit("routes/restoreBypass")
        // }

        // keepRoute("route_temp_4", resp.routePointInfos)

        let weather = []
        // let startdate = store.state.routes.route_settings.date
        // let starttime = store.state.routes.route_settings.time
        let startdate = this.routesStore.routeSettings.startdate
        let starttime = this.routesStore.routeSettings.starttime

        // this.routesStore

        // if (!(startdate === '' || starttime === '')) {
        pts.forEach((coordinates, i) => {
            let lat = coordinates.lat
            let lon = coordinates.lon

            let seconds = store.state.routes.info[i].fromStartSeconds
            // let datetime = moment(startdate + " " + starttime, "YYYY-MM-DD HH:mm:ss")
            let datetime = dayjs(startdate + " " + starttime).format("YYYY-MM-DD HH:mm:ss")
            // let datetimewp = moment(datetime).add(seconds, "seconds")
            let datetimewp = dayjs(datetime).add(seconds, "seconds")

            // console.log("jjj", i, seconds, datetime.calendar(), datetimewp.calendar())
            // console.log("jjj", i, lat, lon, datetimewp, datetimewp.unix())

            // alert("a3")
            // console.log("weather1", moment.unix(datetimewp.unix()).format("DD/MM/YY"))
            let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lon + '&t=' + datetimewp.unix()

            myAxios.get(URL).then(resp => {
                console.log("weather1", resp.data)
                weather.push(resp.data)
            }).catch(error => {
                console.log("weather1", error)
                weather.push({temperature: 'N/A', wind: 'N/A', windAngle: 'N/A'})
            })

            store.commit("fickle/setWeatherWP", weather)

        })
        // }


        if (this.startSegment === 0) {
            pts.forEach((wp, i) => {
                this.addWP(wp, -1, true)
            })

            store.state.routes.routePrevious.forEach((wp, i) => {
                if (i > 1) {
                    this.addWP(store.state.routes.routePrevious[i], -1, false)
                }
            })
        }

        if (this.startSegment !== -1 && this.finishSegment === store.state.routes.routePrevious.length - 1) {
            store.state.routes.routePrevious.forEach((wp, i) => {
                if (i < this.startSegment) {
                    this.addWP(store.state.routes.routePrevious[i], -1, false)
                }
            })
            pts.forEach((wp, i) => {
                this.addWP(wp, -1, false)
            })

        }

        if (this.startSegment === -1 && this.finishSegment === -1) {
            pts.forEach((wp, i) => {
                this.addWP(wp, -1, false)
            })
        }

        // resp.dangerObjects.forEach(obj => {
        //     if (obj.isDanger)
        //         this.setDangerMarkers(obj.points, 'danger');
        //     // this.setDangerMarkers(obj.points, obj.isDanger ? require("../../assets/marker-danger.png") : require("../../assets/marker-warning.png"));
        // })

        this.drawLegs()
        this.debugLegsGroup.clearLayers()
        this.wpLegsGroup.clearLayers()
        // let coorinates = this.map.getCenter();
        // store.commit("leaflet/setMapCenter", coorinates)
        // eventBus.$emit('find-nearest-center-wp')


        this.map.removeLayer(this.wpGlobalGroup)
        this.wpGlobalMarkers.forEach(wp => {
            wp.remove()
        })
        this.wpGlobalMarkers = []
        // this.map.addLayer(this.wpGlobalGroup);

        let globalRoute = []
        this.routesStore.routeGeneral.forEach(wp => {
            this.addGlobalWP({lat: wp.lat, lng: wp.lon})
            globalRoute.push([wp.lat, wp.lon])
        })

        let routeLine = L.polyline(globalRoute, {
            color: 'blue',
            weight: 1,
            dashArray: '5, 5',
            dashOffset: '0'
        }).addTo(this.debugLegsGroup);

    }

    drawGeneralRoute() {
        // if (this.wpGlobalGroup === null)
        //     alert(1)

        this.map.removeLayer(this.wpGlobalGroup)
        this.wpGlobalMarkers.forEach(wp => {
            wp.remove()
        })
        this.wpGlobalMarkers = []
        // this.map.addLayer(this.wpGlobalGroup);

        let globalRoute = []
        this.routesStore.routeGeneral.forEach(wp => {
            this.addGlobalWP({lat: wp.lat, lng: wp.lon})
            globalRoute.push([wp.lat, wp.lon])
        })

        let routeLine = L.polyline(globalRoute, {
            color: 'blue',
            weight: 2,
            dashArray: '5, 5',
            dashOffset: '0'
        }).addTo(this.debugLegsGroup);

        let bounds = routeLine.getBounds()
        this.map.fitBounds(bounds);
    }

    drawLegsByProgress() {
        // this.map.removeLayer(this.wpLegsGroup)
        this.wpLegsMarkers.forEach(wp => {
            wp.remove()
        })
        this.wpLegsMarkers = []
        // this.map.addLayer(this.wpLegsGroup);

        // let legs = []
        let legs = this.routesStore.routeLegs

        this.map.removeLayer(this.wpLegsGroup)
        this.map.addLayer(this.wpLegsGroup)
        legs.forEach(wp => {
            this.addLegWP({lat: wp.lat, lng: wp.lon}, wp.safety)
            // legs.push([wp.lat, wp.lon])
        })

        let leg = []
        console.log("aaaa1", legs)
        for (let i = 0; i < legs.length - 1; i++) {
            leg.push([legs[i].lat, legs[i].lon])
            leg.push([legs[i + 1].lat, legs[i + 1].lon])

            let color = 'black'
            console.log("aaaa2", legs[i].safety)
            switch (legs[i].safety) {
                case 1: color = 'green'
                    break
                case 2: color = 'red'
                    break
            }
            // let color = legs[i] === 2 ? 'red' : 'green'

            // if (color !== 'green') {

                let routeLine = L.polyline(leg, {
                    color: color,
                    weight: 2,
                }).addTo(this.wpLegsGroup);
            // }

            // alert(1)

        }


    }


    showError(resp) {
        let points
        if (store.state.fickle.routes.globalRoute.ports.length > 1) {
            points = store.state.fickle.routes.globalRoute.ports[0] + " : " + store.state.fickle.routes.globalRoute.ports[1]
            store.commit("fickle/clearGlobalRoute")
        } else {
            points = this.routeRequestPoints[0].lat.toFixed(6) + ", " + this.routeRequestPoints[0].lon.toFixed(6) + ' : \n' + this.routeRequestPoints[1].lat.toFixed(6) + ", " + this.routeRequestPoints[1].lon.toFixed(6)
        }


        //  -----------------
        this.routesStore.addRouteGeneral(resp.restoreRoutePoints)

        this.map.removeLayer(this.wpGlobalGroup)
        this.wpGlobalMarkers.forEach(wp => {
            wp.remove()
        })
        this.wpGlobalMarkers = []
        // this.map.addLayer(this.wpGlobalGroup);

        let globalRoute = []
        this.routesStore.routeGeneral.forEach(wp => {
            this.addGlobalWP({lat: wp.lat, lng: wp.lon})
            globalRoute.push([wp.lat, wp.lon])
        })

        let routeLine = L.polyline(globalRoute, {
            color: 'blue',
            weight: 1,
            dashArray: '5, 5',
            dashOffset: '0'
        }).addTo(this.debugLegsGroup);
        //  -----------------


        prepareError(resp.errorCode, points)
    }

    drawPort(coordinates, name) {
        let newMarker = L.circleMarker(coordinates, {
            radius: 4,
            weight: 1,
            color: '#ff0000',
            // opacity:0.3
        })

        // let coordinates = e.latlng

        // console.log(content)
        //
        // document.getElementById(id1).addEventListener('click', (e) => {
        //     this.map.closePopup()
        //     getDepth(coordinates)

        // newMarker.on('popupopen', (e) => {
        //     const popup = e.popup;
        //     const URL = 'api/v1/depth?lat=' + coordinates.lat + '&lon=' + coordinates.lng
        //     const content = popup.getContent()
        //
        //     if (!content.includes("depth:")) {
        //         myAxios.get(URL).then(resp => {
        //             // popup.setContent('Coordinates: ' +  popup.getLatLng().lng + ' / ' + popup.getLatLng().lat);
        //             popup.setContent(content + " (depth: " + resp.data + ' m)');
        //         })
        //     }
        // })

        newMarker.on('click', (e) => {
            eventBus.$emit('port-clicked', name)
        });

        newMarker.bindPopup(name);
        newMarker.addTo(this.portsLayer)

        this.portsMarkers.push(newMarker);
            // newMarker.bindPopup(name).addTo(this.portsLayer);
    }

    clearPorts() {

        if (this.portsLayer) {

            this.portsLayer.removeFrom(this.map);
            // this.portsLayer.clearLayers();
            // this.portsLayer = null
            // this.portsMarkers.splice(0, this.portsMarkers.length)
            // this.portsMarkers = null;
            // this.portsMarkers = [];

            // for (let i = 0; i < this.portsMarkers.length; i++) {
            //     this.portsMarkers[i].remove()
            // }
            //
            // for (let i = 0; i < this.portsMarkers.length; i++) {
            //     this.portsMarkers.pop()
            // }
            //
            // this.map.removeLayer(this.portsLayer)
            // this.portsLayer = null

            // --------------------

            // this.portsMarkers.forEach(wp => {
            //     wp.remove()
            //     wp.pop()
            // })
            //
            // this.map.removeLayer(this.portsLayer)
            // this.portsLayer = null

            // --------------------

            // this.map.removeLayer(this.portsLayer)

            // --------------------

            // this.portsMarkers.forEach(wp => {
            //     if (this.map.hasLayer(wp)) {
            //         this.map.removeLayer(wp);
            //     }
            // })

            // --------------------

            // for (let i = 0; i < this.portsMarkers.length; i++) {
            //     this.portsMarkers[i].remove()
            // }

            // for (let i = 0; i < this.portsMarkers.length; i++) {
            //     this.portsMarkers.pop()
            // }

            // this.portsMarkers.length = 0
            // this.map.removeLayer(this.portsLayer)
            // this.portsLayer = null
        }
    }

    drawPorts() {
        // this.clearPorts()
        // alert('draw ports func')
        // let flag = this.map.hasLayer(this.portsLayer);
        // console.log(flag, 'layer')
        //
        // if (!this.map.hasLayer(this.portsLayer)) {
        //     this.portsLayer.addTo(this.map);
        // }
        // if (!this.portsLayer) {
            this.portsLayer = L.layerGroup();
            this.portsMarkers = [];

            // portsJSON.forEach((item) => {
            //     this.drawPort({lat: item.lat, lng: item.lon}, item.name)
            // })
            this.portsLayer.addTo(this.map)
            // this.portsLayer.eachLayer((layer) => {layer.bindTooltip('name');})
        // }

    }
}
