import L from "leaflet";
import store from '../../store'
import {eventBus} from "@/main";
import "../extensions/leaflet-corridor"
import "../extensions/direct"
import {destVincenty, toDeg} from "@/my-leaf/extensions/direct";
import {myAxios} from "@/services/myAxios";
import './route-styles.css'
import "../extensions/leaflet-corridor"

import "../extensions/leaflet-search/leaflet-search.min.js"
import "../extensions/leaflet-search/leaflet-search.min.css"

import '@fortawesome/fontawesome-free/js/fontawesome'
//import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'

import {showError} from "@/my-leaf/routes/route-errors";
import {RouteBaseWPLeaf} from "@/my-leaf/routes/route-leaf-base-wp";
import {getDepth, getWeather, showInfo} from "@/my-leaf/routes/route-utils";
import {RouteBaseRoutingLeaf} from "@/my-leaf/routes/route-leaf-base-routing";
import {pointGrid} from "@turf/turf";
import GeometryUtil from "leaflet-geometryutil";
// import moment from "moment/moment";
import * as dayjs from 'dayjs/dayjs.min'

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

// let progressCounter = 0;
// let prevProgress = -1;
let prevDraught = -1;
let prevWeatherTime = -1;

let isDrawStarted = false

let isMeasureStarted = false

let i = 0
let i1 = 0
let popup = null

// let allObjectsPolyline = []
let objectsPolyline = L.layerGroup();
let allObjectsPolyline = L.layerGroup();
let pointsLayer = L.featureGroup();
let zonesLayer = L.featureGroup();
let allZonesLayer = L.featureGroup();

//let nogoList = null;

// let startSegment = -1
// let finishSegment = -1

export class RouteLeaf extends RouteBaseRoutingLeaf {

    constructor() {
        super();
    }

    share_control = null
    depthsList = null

    isZonesTab = false

    aaaa = () => {

        objectsPolyline.clearLayers()


        // objectsPolyline.forEach(object => {
        //     this.map.removeLayer(object);
        // })
        // objectsPolyline = []
        this.map.removeLayer(pointsLayer)
    }

    drawStartFinish = (wp1, wp2) => {

        this.debugLegsGroup.clearLayers()
        console.log("aaaa", wp1, wp2)
        let newMarker1 = new L.marker(wp1, {draggable: false, autoPan: true, icon: this.wpIconDefault}).addTo(this.debugLegsGroup);
        newMarker1.bindTooltip('S', {permanent: true}).openTooltip();

        let newMarker2 = new L.marker(wp2, {draggable: false, autoPan: true, icon: this.wpIconDefault}).addTo(this.debugLegsGroup);
        newMarker2.bindTooltip('F', {permanent: true}).openTooltip();
    }

    drawDebugLine = (wp1, wp2) => {

        this.debugLegsGroup.clearLayers()
        let routeLine = L.polyline([wp1.getLatLng(), wp2.getLatLng()], {color: 'blue', weight: 1}).addTo(this.debugLegsGroup);

        let newMarker1 = new L.marker(wp1.getLatLng(), {draggable: false, autoPan: true, icon: this.wpIconDefault}).addTo(this.debugLegsGroup);
        newMarker1.bindTooltip('S', {permanent: true}).openTooltip();

        let newMarker2 = new L.marker(wp2.getLatLng(), {draggable: false, autoPan: true, icon: this.wpIconDefault}).addTo(this.debugLegsGroup);
        newMarker2.bindTooltip('F', {permanent: true}).openTooltip();
    }

    recalculateWP = (wpMarker) => {
        console.log("qqq", wpMarker)
        let wpIndex = wpMarker.options.id
        console.log("qqq", wpIndex)
        this.startSegment = -1
        this.finishSegment = -1

        if (wpIndex === 0) {
            this.startSegment = 0
            this.finishSegment = 1
            this.calculateRoute(0, 1)
        }
        if (wpIndex === this.wpMarkers.length - 1) {
            this.startSegment = wpIndex - 1
            this.finishSegment = wpIndex
            this.calculateRoute(wpIndex - 1, wpIndex)
        }

        if (wpIndex > 0 && wpIndex < this.wpMarkers.length - 1) {
            console.log("qqq", "next")
            this.startSegment = wpIndex - 1
            this.finishSegment = wpIndex + 1
            this.calculateRoute(wpIndex - 1, wpIndex + 1)
        }

        // eventBus.$emit('recalculate_one', wpIconDefault.options.id)
    }

    // removeRoute = () => {
    //     this.wpMarkers.forEach(wp => {
    //         this.removeWP(wp, false)
    //     })
    //
    //     this.drawLegs()
    //     this.wpIndex = 0
    //     this.removeSendToOrderCntrol()
    // }
    //
    // removeRouteWithDebug = () => {
    //     this.wpMarkers.forEach(wp => {
    //         this.removeWP(wp, false)
    //     })
    //
    //     this.drawLegs()
    //     this.wpIndex = 0
    //     this.removeSendToOrderCntrol()
    //     this.debugLegsGroup.clearLayers()
    // }

    // calculateRoute = (wpIndexStart = -1, wpIndexFinish = -1) => {
    //     let resctrictedZones = [];
    //     nogoList.getLayers().forEach(area => {
    //         let bounds = area.getBounds()
    //         let restZone = {
    //             "south": bounds.getSouth(),
    //             "north": bounds.getNorth(),
    //             "west": bounds.getWest(),
    //             "east": bounds.getEast()
    //         }
    //         resctrictedZones.push(restZone)
    //     })
    //
    //     let startIndex = 0
    //     let finishIndex = this.wpMarkers.length - 1
    //     let midIndex = -1
    //
    //     if (wpIndexFinish - wpIndexStart === 1) {
    //         startIndex = wpIndexStart
    //         finishIndex = wpIndexFinish
    //     }
    //
    //     if (wpIndexFinish - wpIndexStart === 2) {
    //         startIndex = wpIndexStart
    //         finishIndex = wpIndexFinish
    //         midIndex = wpIndexFinish - 1
    //     }
    //
    //     let group = new L.featureGroup([this.wpMarkers[startIndex], this.wpMarkers[finishIndex]]);
    //     this.map.fitBounds(group.getBounds())
    //
    //     this.drawDebugLine(this.wpMarkers[startIndex], this.wpMarkers[finishIndex])
    //
    //     if (finishIndex === startIndex + 2) {
    //         eventBus.$emit('change-draught2', {
    //             lat1: this.wpMarkers[startIndex].getLatLng().lat,
    //             lng1: this.wpMarkers[startIndex].getLatLng().lng,
    //             lat2: this.wpMarkers[midIndex].getLatLng().lat,
    //             lng2: this.wpMarkers[midIndex].getLatLng().lng,
    //             lat3: this.wpMarkers[finishIndex].getLatLng().lat,
    //             lng3: this.wpMarkers[finishIndex].getLatLng().lng,
    //         }, resctrictedZones);
    //     } else {
    //         eventBus.$emit('change-draught1', {
    //             lat1: this.wpMarkers[startIndex].getLatLng().lat,
    //             lng1: this.wpMarkers[startIndex].getLatLng().lng,
    //             lat2: this.wpMarkers[finishIndex].getLatLng().lat,
    //             lng2: this.wpMarkers[finishIndex].getLatLng().lng,
    //         }, resctrictedZones);
    //     }
    // }

    // async getRoute(routeId) {
    //     return new Promise((resolve) => {
    //         // get route progress and state
    //         myAxios.get('api/v1/route/' + routeId)
    //             .then(resp => {
    //                 console.log(resp)
    //
    //                 store.commit("debug/setRouteResponce", resp)
    //
    //                 console.log(": ", progressCounter, resp.data.progress, prevProgress)
    //
    //                 if (prevProgress !== resp.data.progress) {
    //                     prevProgress = resp.data.progress;
    //                     progressCounter = 0;
    //                 } else {
    //                     progressCounter++;
    //                 }
    //                 store.commit("routes/setProgress", resp.data.progress)
    //
    //
    //                 if (resp.data.errorCode !== 0 || resp.status === 'done' || this.cancel_auto_route) {
    //                     this.cancel_auto_route = false
    //                     resolve(resp.data);
    //                 } else {
    //                     if (resp.data.isSuccess) {
    //                         resolve(resp.data);
    //                     } else {
    //                         if (!this.cancel_auto_route) {
    //                             setTimeout(() => {
    //                                 console.log("route calculation...")
    //                                 resolve(this.getRoute(routeId))
    //                             }, 1500);
    //                         }
    //                     }
    //                 }
    //             })
    //     })
    // }

    removeSendToOrderCntrol() {
        if (this.share_control) {
            this.map.removeControl(this.share_control)
            this.share_control = null
            store.commit("routes/useRouteForOrder", false)
        }
    }

    showWeather = (coordinates) => {
        myAxios.get(`https://nogotiles.bgeo.fi:6011/weather_info?lat=${coordinates.lat}&lon=${coordinates.lng}`)
            .then(resp => {
                let geoJsonLayer = L.geoJson(resp.data, {
                    style: function(feature) {
                        return feature.properties && feature.properties.style;
                    },
                    onEachFeature: function(f, l) {
                        console.log("aaa4", f.properties)
                        delete f.properties.style
                        l.bindPopup('<pre>' + JSON.stringify(f.properties, null, ' ').replace(/[\{\}"]/g, '') + '</pre>');
                    }
                }).addTo(this.depthsList)
            });
    }

    showDepths = (coordinates) => {
        myAxios.get(`api/v1/route/depth_info?lat=${coordinates.lat}&lon=${coordinates.lng}&radius=5`)
            .then(resp => {
                this.depthsList.clearLayers()
                let geoJsonLayer = L.geoJson(resp.data, {
                    style: function(feature) {
                        return feature.properties && feature.properties.style;
                    },
                    onEachFeature: function(f, l) {
                        console.log("aaa5", f.properties)
                        delete f.properties.style
                        l.bindPopup('<pre>' + JSON.stringify(f.properties, null, ' ').replace(/[\{\}"]/g, '') + '</pre>');
                    }
                }).addTo(this.depthsList)
            });
    }

    async init(el) {
        super.initbaserouting(el);

        this.depthsList = new L.FeatureGroup();
        this.map.addLayer(this.depthsList);
        this.map.addLayer(pointsLayer);
        this.map.addLayer(objectsPolyline);
        this.map.addLayer(allObjectsPolyline);
        this.map.addLayer(zonesLayer)
        this.map.addLayer(allZonesLayer)

        this.map.on('polylinemeasure:toggle', (e) => {
            console.log("aaa6", this.measureControl._measureControl)
            if (e.sttus) {
                console.log("aaaa", this.measureControl)
                // this.measureControl.style = {color: 'green'}
                this.measureControl._measureControl.style.backgroundColor = '#0dbcd2'
                this.measureControl._clearMeasureControl.hidden = false
                this.measureControl._unitControl.hidden = false
                isMeasureStarted = true
            }
            else {
                this.measureControl._measureControl.style.backgroundColor = 'white'
                this.measureControl._clearMeasureControl.hidden = true
                this.measureControl._unitControl.hidden = true
                isMeasureStarted = false
            }

        })

        this.map.on('contextmenu', (e) => {
            // console.log("aaaa", e)
            // this.map.closePopup()
            // console.log("aaaa", e)

            // setTimeout(() => {

            let id1 = 'map_depth' + i1;
            let id2 = 'map_info' + i1;
            let id3 = 'depths_info' + i1;
            let id4 = 'weather_info' + i1;

            let content = `<button id="` + id1 + `" onclick="">Depth</button>&nbsp;
                           <button id="` + id2 + `" onclick="">Map info</button>
                           <button id="` + id3 + `" onclick="">Depths info</button>
                           <button id="` + id4 + `" onclick="">Weather</button>`
            popup = L.popup({autoClose: true}).setLatLng(e.latlng).setContent(content).openOn(this.map);
            let coordinates = e.latlng

            console.log(content)

            document.getElementById(id1).addEventListener('click', (e) => {
                this.map.closePopup()
                getDepth(coordinates)
            });

            document.getElementById(id2).addEventListener('click', (e) => {
                this.map.closePopup()
                showInfo(coordinates)
            });

            document.getElementById(id3).addEventListener('click', (e) => {
                this.map.closePopup()
                this.showDepths(coordinates)
            });

            document.getElementById(id4).addEventListener('click', (e) => {
                this.map.closePopup()
                getWeather(coordinates)
            });

            i1++

            // }, 100); //TODO

        })

        // nogoList = new L.FeatureGroup();
        // this.map.addLayer(nogoList);

        // let options = {
        //     position: 'topleft',
        //     draw: {
        //         polyline: false,
        //         circle: false,
        //         polygon: false,
        //         marker: false,
        //         rectangle: {shapeOptions: {color: '#f357a1', weight: 1}},
        //         circlemarker: false
        //     },
        //     edit: {
        //         featureGroup: nogoList,
        //         remove: true
        //     }
        // };
        //
        // this.drawControl = new L.Control.Draw(options)
        // this.map.on('draw:created', function (e) {
        //     let type = e.layerType,
        //         layer = e.layer;
        //
        //     console.log("draw created")
        //     nogoList.addLayer(layer);
        // });


        this.corridorLayer1 = L.layerGroup().addTo(this.map);
        this.corridorLayer2 = L.layerGroup().addTo(this.map);
        this.corridorLayer3 = L.layerGroup().addTo(this.map);
        this.warningMarkers = L.layerGroup().addTo(this.markerLayer);
        this.dangerMarkers = L.layerGroup().addTo(this.markerLayer);
        this.setTileLayerWithDraught()
        this.clearRoute();
        const a = document.getElementById('polyline-measure-control')
        let corridorPane = this.map.createPane('corridor');
        corridorPane.style.zIndex = 200;


        this.wpGroup = L.layerGroup();
        this.map.addLayer(this.wpGroup);

        this.wpGlobalGroup = L.layerGroup();
        this.map.addLayer(this.wpGlobalGroup);

        this.legsGroup = L.layerGroup();
        this.map.addLayer(this.legsGroup);

        this.debugLegsGroup = L.layerGroup();
        this.map.addLayer(this.debugLegsGroup);

        this.wpLegsGroup = L.layerGroup();
        this.map.addLayer(this.wpLegsGroup);

        eventBus.$off('draw-start-finish')
        eventBus.$on('draw-start-finish', (wp1, wp2) => {
            this.drawStartFinish(wp1, wp2)
        })

        eventBus.$off('draw-global-route')
        eventBus.$on('draw-global-route', () => {
            eventBus.$emit('control-clear-route')
            // eventBus.$emit('control-clear-global-route')

            // this.wpGlobalMarkers.forEach(wp => {
            //     wp.remove()
            // })

            // alert(store.state.fickle.routes.globalRoute.waypoints.length)

            this.startRouteCalculation([], [], true, true)

            // let globalRoute = []
            // this.routesStore.routeGeneral.forEach(wp => {
            //     this.addGlobalWP({lat:wp.lat, lng:wp.lon})
            //     globalRoute.push([wp.lat, wp.lon])
            // })
            //
            // let routeLine = L.polyline(globalRoute, {color: 'blue', weight: 1, dashArray: '5, 5', dashOffset: '0'}).addTo(this.debugLegsGroup);
        })

        eventBus.$off('hide-zones')
        eventBus.$on('hide-zones', () => {
            this.isZonesTab = false
            this.map.removeLayer(allObjectsPolyline);
            this.map.removeLayer(objectsPolyline);
        })

        eventBus.$off('show-zones')
        eventBus.$on('show-zones', () => {
            this.isZonesTab = true

            const zoom = this.map.getZoom();

            if (zoom >= 8) {
                this.map.addLayer(allObjectsPolyline);
                this.map.addLayer(objectsPolyline);
            }
        })


        eventBus.$off("clear-object")
        eventBus.$on('clear-object', () => {

            // allObjectsPolyline.forEach(object => {
            //     this.map.removeLayer(object);
            // })
            // allObjectsPolyline = []

            allObjectsPolyline.clearLayers()
            objectsPolyline.clearLayers()


            // objectsPolyline.forEach(object => {
            //     this.map.removeLayer(object);
            // })
            // objectsPolyline = []
            this.map.removeLayer(pointsLayer)
        })

        eventBus.$off("clear-selected-object")
        eventBus.$on('clear-selected-object', () => {

            // allObjectsPolyline.forEach(object => {
            //     this.map.removeLayer(object);
            // })
            // allObjectsPolyline = []

            // allObjectsPolyline.clearLayers()

            objectsPolyline.clearLayers()

            // objectsPolyline.forEach(object => {
            //     this.map.removeLayer(object);
            // })
            // objectsPolyline = []
            this.map.removeLayer(pointsLayer)
        })

        eventBus.$off("clear-zone")
        eventBus.$on("clear-zone", (name) => {
            zonesLayer.clearLayers()
        })

        // eventBus.$off("clear-nogo-objects")
        // eventBus.$on("clear-nogo-objects", (name) => {
        //     objectsPolyline.forEach(object => {
        //         this.map.removeLayer(object);
        //     })
        // })

        eventBus.$off("draw-zone")
        eventBus.$on("draw-zone", (name) => {
            let zone = this.routesStore.nogoZones.filter(function (e) {
                return e.name === name
            })

            zonesLayer.clearLayers()

            let bounds = []
            zone[0].zones.forEach(coordinates => {
                let rectangle = [[coordinates.north, coordinates.west], [coordinates.south, coordinates.east]];
                bounds.push([coordinates.north, coordinates.west])
                bounds.push([coordinates.south, coordinates.east])
                L.rectangle(rectangle, {color: "#00ff51", weight: 1}).addTo(zonesLayer);
            })
            this.map.fitBounds(bounds);
        })


        eventBus.$off("redraw-zones")
        eventBus.$on("redraw-zones", () => {

            zonesLayer.clearLayers()
            allZonesLayer.clearLayers()
            store.state.fickle.routes.nogoZones.forEach(zoneName => {

                let zone = this.routesStore.nogoZones.filter(function (e) {
                    return e.name === zoneName
                })

                let bounds = []
                zone[0].zones.forEach(coordinates => {
                    let rectangle = [[coordinates.north, coordinates.west], [coordinates.south, coordinates.east]];
                    bounds.push([coordinates.north, coordinates.west])
                    bounds.push([coordinates.south, coordinates.east])
                    L.rectangle(rectangle, {color: "#ff7800", weight: 1}).addTo(allZonesLayer);
                })
                this.map.fitBounds(bounds);

            })
        })

        eventBus.$off("draw-all-nogo-objects")
        eventBus.$on("draw-all-nogo-objects", () => {

            // alert(1)
            allObjectsPolyline.clearLayers()

            let size = 0
            let objects = []

            if (!this.isZonesTab)
                this.map.removeLayer(allObjectsPolyline);

            store.state.fickle.routes.metric.forEach(data => {
                let lines = data.lines
                let id = data.id
                let name = data.name
                // let type = data.locationType

                if (name === "Traffic separation zone" ||
                    name === "Inshore traffic zone" ||
                    name === "Caution area" ||
                    name === "Offshore production area" ||
                    name === "Military practice area" ||
                    name === "Sea-plane landing area" ||
                    name === "Submarine transit lane" ||
                    name === "Anchorage area" ||
                    name === "Marine farm/culture" ||
                    name.startsWith("Restricted area")
                ) {
                    let object
                    lines.forEach(line => {
                        object = L.polygon(line, {
                            color: '#5e00ff',
                            weight: 3,
                            opacity: 1,
                            fillOpacity: 0,
                            smoothFactor: 1,
                            bubblingMouseEvents: false
                        });

                        size = GeometryUtil.geodesicArea(object.getLatLngs()[0]);

                        // object.onclick = (e) => {
                        //     e.stopPropagation();
                        //     e.preventDefault();
                        //     console.log("aaaaaaaaa", id)
                        // }

                        object.on('click', function (e) {
                            console.log("aaaaaaaaa1", id)
                            console.log("aaaaaaaaa1", e)
                            // e.originalEvent.cancelBubble = true
                            // e.preventDefault();
                            eventBus.$emit('select-object', id)
                            e.originalEvent.preventDefault();
                            e.originalEvent.stopPropagation();
                            // return false
                        });

                        objects.push({obj: object, size: size, id:id})


                        // object.addTo(allObjectsPolyline);

                        // allObjectsPolyline.push(object)
                    })


                    // console.log("iiii", id, size)
                }

                // if (type ===  "point") {
                //     let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
                //     this.map.addLayer(pointsLayer)
                //     objectCircle.addTo(pointsLayer);
                // }


            })

            console.log("iiii2", objects)
            objects = objects.sort((a, b) => b.size - a.size);
            console.log("iiii4", objects)


            objects.forEach(item => {
                item.obj.addTo(allObjectsPolyline)
            })


        })

        eventBus.$off("draw-all-nogo-objects2")
        eventBus.$on("draw-all-nogo-objects2", () => {

            allObjectsPolyline.clearLayers()

            store.state.routes.dangers.forEach(data => {
                let lines = data.points
                let id = data.id
                let name = data.name

                if (name === "Traffic separation zone" ||
                    name === "Inshore traffic zone" ||
                    name === "Caution area" ||
                    name === "Offshore production area" ||
                    name === "Military practice area" ||
                    name === "Sea-plane landing area" ||
                    name === "Submarine transit lane" ||
                    name === "Anchorage area" ||
                    name === "Marine farm/culture" ||
                    name.startsWith("Restricted area")
                ) {
                    // lines.forEach(line => {
                        let object = L.polygon(lines, {
                            color: '#5e00ff',
                            weight: 3,
                            opacity: 1,
                            fillOpacity: 0,
                            smoothFactor: 1,
                            bubblingMouseEvents: false
                        });


                        // object.onclick = (e) => {
                        //     e.stopPropagation();
                        //     e.preventDefault();
                        //     console.log("aaaaaaaaa", id)
                        // }

                        object.on('click', function (e) {
                            console.log("aaaaaaaaa1", id)
                            console.log("aaaaaaaaa1", e)
                            // e.originalEvent.cancelBubble = true
                            // e.preventDefault();
                            eventBus.$emit('select-object', id)
                            e.originalEvent.preventDefault();
                            e.originalEvent.stopPropagation();
                            // return false
                        });

                        object.addTo(allObjectsPolyline);

                        // allObjectsPolyline.push(object)
                    // })
                }

                // if (type ===  "point") {
                //     let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
                //     this.map.addLayer(pointsLayer)
                //     objectCircle.addTo(pointsLayer);
                // }


            })
        })

        eventBus.$off("draw-nogo-object")
        eventBus.$on("draw-nogo-object", (data) => {

            let lines = data.lines
            let id = data.id
            let name = data.name
            let type = data.locationType

            console.log("data", data)

            // if (name === "Depth area") {
            //     lines.pop()
            // }

            if (type ===  "point") {
                let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
                this.map.addLayer(pointsLayer)
                objectCircle.addTo(pointsLayer);
            }

            objectsPolyline.clearLayers()
            // objectsPolyline.forEach(object => {
            //     this.map.removeLayer(object);
            // })
            // objectsPolyline = []

            // lines.forEach(line => {
                let object = new L.Polygon(lines, {
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1,
                    bubblingMouseEvents: false
                });
                // this.map.addLayer(object);
                // objectsPolyline.push(object)

                object.on('click', function (e) {
                    console.log("aaaaaaaaa1", id)
                    console.log("aaaaaaaaa1", e)
                    // e.originalEvent.cancelBubble = true
                    // e.preventDefault();
                    eventBus.$emit('hide-object', id)
                    e.originalEvent.preventDefault();
                    e.originalEvent.stopPropagation();
                    // return false
                });

                object.addTo(objectsPolyline)

            // })

            let allCoordinates = []
            lines.forEach(line => {
                line.forEach(point => {
                    allCoordinates.push([point.lat, point.lon])
                })
            })
            let p = L.polygon(allCoordinates)
            let b = p.getBounds()
            this.map.fitBounds(b)
        })

        eventBus.$off("clear-object")
        eventBus.$on("clear-object", () => {
            objectsPolyline.clearLayers()
            pointsLayer.clearLayers()
        })

        eventBus.$off("draw-object")
        eventBus.$on("draw-object", (payload) => {

            let lines = payload.lines
            let type = payload.type
            let needZoom = payload.zoom

            console.log(type, lines)

            // console.log(lines.length)

            if (type ===  "point") {
                // let pointObject = L.divIcon({
                //     className: '',
                //     iconSize: [40, 40],
                //     iconAnchor: [25, 25],
                //     html: "<div style='font-size: 40px;background-color:\'0x00ff00\''>&#11093;</div>"
                // });

                // new L.marker([lines[0][0].lat, lines[0][0].lon], {icon: pointObject}).addTo(this.map);

                let objectCircle = new L.circle([lines[0][0].lat, lines[0][0].lon], 10)
                // this.map.removeLayer(pointsLayer)
                this.map.addLayer(pointsLayer)
                objectCircle.addTo(pointsLayer);

            }


            objectsPolyline.clearLayers()

            // objectsPolyline.forEach(object => {
            //     this.map.removeLayer(object);
            // })
            // objectsPolyline = []
            // this.map.removeLayer(pointsLayer)

            // lines.forEach(line => {
            //     let object = new L.Polygon(line, {
            //         // color: '#00ff00',
            //         color: '#5e00ff',
            //         weight: 3,
            //         opacity: 1,
            //         smoothFactor: 1
            //     });
            //     object.addTo(objectsPolyline)
            // })

            // lines.forEach(line => {

            if (type === 'line') {
                let object = new L.Polyline(lines, {
                    // color: '#00ff00',
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1
                });
                object.addTo(objectsPolyline)

            } else {
                let object = new L.Polygon(lines, {
                    // color: '#00ff00',
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1
                });
                object.addTo(objectsPolyline)

            }

            // })


            if (needZoom) {
                let allCoordinates = []
                lines.forEach(line => {
                    line.forEach(point => {
                        allCoordinates.push([point.lat, point.lon])
                    })
                })
                console.log("nnnn", allCoordinates)

                let p
                if (type === 'line') {
                    p = L.polyline(allCoordinates)
                } else {
                    p = L.polygon(allCoordinates)
                }

                // this.map.addLayer(p);

                // let bounds = L.LatLngBounds(allCoordinates)
                let b = p.getBounds()
                this.map.fitBounds(b)
            }


            // if (objectPolyline !== null)
            //     this.map.removeLayer(objectPolyline);
            // objectPolyline = new L.Polyline(lines[0], {
            //     color: '#00ff00',
            //     weight: 4,
            //     opacity: 1,
            //     smoothFactor: 1
            // });
            // this.map.addLayer(objectPolyline);
        })

        eventBus.$off("draw-danger-object-in-leg")
        eventBus.$on("draw-danger-object-in-leg", (payload) => {

            let lines = payload.lines
            let type = payload.type
            let needZoom = payload.zoom

            console.log("aaaa", lines, type)

            if (type ===  'point' || lines.length === 1) {
                let objectCircle = new L.circle([lines[0].lat, lines[0].lon], 10)
                pointsLayer.clearLayers()
                this.map.addLayer(pointsLayer)
                objectCircle.addTo(pointsLayer);
            }

            if (type === 'line' && lines.length > 1) {
                let object = new L.Polyline(lines, {
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1
                });
                pointsLayer.clearLayers()
                this.map.addLayer(pointsLayer)
                object.addTo(pointsLayer)
            }

            if (type === 'area' && lines.length > 1) {
                let object = new L.Polygon(lines, {
                    color: '#5e00ff',
                    weight: 3,
                    opacity: 1,
                    smoothFactor: 1
                });
                pointsLayer.clearLayers()
                this.map.addLayer(pointsLayer)
                object.addTo(pointsLayer)
            }

            // objectsPolyline.clearLayers()

            // if (type === 'line') {
            //     let object = new L.Polyline(lines, {
            //         color: '#5e00ff',
            //         weight: 3,
            //         opacity: 1,
            //         smoothFactor: 1
            //     });
            //     object.addTo(objectsPolyline)
            //
            // } else if (type === 'area') {
            //     let object = new L.Polygon(lines, {
            //         color: '#5e00ff',
            //         weight: 3,
            //         opacity: 1,
            //         smoothFactor: 1
            //     });
            //     object.addTo(objectsPolyline)
            //
            // }

            if (needZoom) {
                let allCoordinates = []
                    lines.forEach(point => {
                        allCoordinates.push([point.lat, point.lon])
                    })
                console.log("nnnn", allCoordinates)

                let p
                if (type === 'line' && lines.length > 1) {
                    p = L.polyline(allCoordinates)
                } else {
                    p = L.polygon(allCoordinates)
                }

                let b = p.getBounds()
                this.map.fitBounds(b)
            }

        })

        eventBus.$off('control-clear-global-route')
        eventBus.$on('control-clear-global-route', () => {
            this.map.removeLayer(this.wpGlobalGroup)

            this.wpGlobalMarkers.forEach(wp => {
                wp.remove()
            })

        })


        eventBus.$off('control-clear-route')
        eventBus.$on('control-clear-route', () => {
            // this.map.removeLayer(this.wpGlobalGroup)
            this.removeRouteWithDebug()
            this.map.removeControl(this.moveControl)
            eventBus.$emit('show-wp-index1', -1)
            store.commit("fickle/setWeatherWP", [])
            // store.commit("fickle/clearGlobalRoute")
        })

        eventBus.$off('control-recalculate-all')
        eventBus.$on('control-recalculate-all', () => {
            this.calculateRoute()
        })

        eventBus.$off('move-wp-recalculate')
        eventBus.$on('move-wp-recalculate', () => {
            this.recalculateWP(this.wpMarkers[store.state.routes.movingWP])
        })

        eventBus.$off('info-pressed')
        eventBus.$on('info-pressed', () => {
            this.map.closePopup()
            let center = this.map.getCenter()

            let content = `<button id="map_depth" onclick="">Depth</button>&nbsp;
                           <button id="map_info" onclick="">Map info</button>
                           <button id="depths_info" onclick="">Depths info</button>
                           <button id="weather_info" onclick="">Weather info</button>`
            popup = L.popup({autoClose: false}).setLatLng(center).setContent(content).openOn(this.map);
            let coordinates = center

            document.getElementById('map_depth').addEventListener('click', (e) => {
                this.map.closePopup()
                getDepth(coordinates)
            });

            document.getElementById('map_info').addEventListener('click', (e) => {
                this.map.closePopup()
                showInfo(coordinates)
            });

            document.getElementById('depths_info').addEventListener('click', (e) => {
                this.map.closePopup()
                this.showDepths(coordinates)
            });
        })

        eventBus.$off('find-nearest-center-wp')
        eventBus.$on('find-nearest-center-wp', () => {
            let center = [store.state.leaflet.map.lat, store.state.leaflet.map.lon]
            let prevDistance = -1
            let prevIndex = -1
            this.wpMarkers.forEach((wp, i) => {
                let distance = wp.getLatLng().distanceTo(center)
                if (prevDistance === -1 || prevDistance > distance) {
                    prevDistance = distance
                    prevIndex = i
                }
            })
            console.log("aaa7", prevIndex, prevDistance)
            eventBus.$emit('set-center-index', prevIndex)

            let scale = L.control.scale()
            let meters = scale._getRoundNum(this.map.containerPointToLatLng([0, this.map.getSize().y / 2 ]).distanceTo( this.map.containerPointToLatLng([scale.options.maxWidth,this.map.getSize().y / 2 ])))


            // this.wpMarkers.forEach(wp => {
            //     wp.setIcon(this.wpIconDefault)
            //     // this.focusWPIndex = -1
            //     eventBus.$emit('set-focus-index', -1)
            //     eventBus.$emit('show-wp-index', -1)
            // })


            eventBus.$emit('set-focus-index', prevIndex)

            // if (prevDistance > meters / 4) {
            //     eventBus.$emit('show-wp-index1', -1)
            //     eventBus.$emit('clear-current-wp-index')
            //     this.map.removeControl(this.moveControl)
            //
            //     this.wpMarkers.forEach(wp => {
            //         wp.setIcon(this.wpIconDefault)
            //     })
            // }

                        // console.log("aaa", prevDistance, meters)
                        // if (prevDistance < meters / 4) {
                        //     // this.focusWPIndex = prevIndex
                        //     this.wpMarkers[prevIndex].setIcon(this.wpIconRed)
                        //     eventBus.$emit('show-wp-index', prevIndex)
                        // }


                        // eventBus.$emit('select-routetable-row', prevIndex)
                        // console.log("aaa", prevIndex)
        })

        eventBus.$off('route-by-coordinates')
        eventBus.$on('route-by-coordinates', () => {
            let value = prompt("Enter coordinates [lat1,lon1:lat2,lon2]: ")
            let coordinates = value.trim().split(':')
            let startCoordinates = coordinates[0].split(',')
            let finishCoordinates = coordinates[1].split(',')

            let lat1 = startCoordinates[0]
            let lon1 = startCoordinates[1]
            let lat2 = finishCoordinates[0]
            let lon2 = finishCoordinates[1]

            this.addWP({lat: lat1, lng: lon1})
            this.autorouting_points++
            this.addWP({lat: lat2, lng: lon2})
            this.autorouting_points++
            this.calculateRoute()
        })

        eventBus.$off('show-depth')
        eventBus.$on('show-depth', (payload) => {
            L.popup().setLatLng(payload.coordinates).setContent(payload.depth + ' m').openOn(this.map);
        })

        eventBus.$off('show-weather-popup')
        eventBus.$on('show-weather-popup', (payload) => {
            console.log('ggg', payload.weather)
            let temperature = ''
            let wind = ''
            if (!payload.weather.temperature.toString().startsWith('1.7976931348'))
                temperature = 'Temperature ' + payload.weather.temperature + '° C'

            if (!payload.weather.wind.toString().startsWith('1.7976931348'))
                wind = 'Wind ' + payload.weather.wind + ' m/s (' + payload.weather.windAngle + '°)'

            let content = temperature ? temperature  + '<br>': ''
            if (wind)
                content += wind

            L.popup().setLatLng(payload.coordinates).setContent(content).openOn(this.map);
        })

        // eventBus.$off('recalculate_all')
        // eventBus.$on('recalculate_all', () => {
        //     this.calculateRoute()
        // })

        // eventBus.$off('recalculate_one')
        // eventBus.$on('recalculate_one', (wpIndex) => {
        //     this.startSegment = -1
        //     this.finishSegment = -1
        //
        //     if (wpIndex === 0) {
        //         this.startSegment = 0
        //         this.finishSegment = 1
        //         this.calculateRoute(0, 1)
        //     }
        //     if (wpIndex === this.wpMarkers.length - 1) {
        //         this.startSegment = wpIndex - 1
        //         this.finishSegment = wpIndex
        //         this.calculateRoute(wpIndex - 1, wpIndex)
        //     }
        //
        //     if (wpIndex > 0 && wpIndex < this.wpMarkers.length - 1) {
        //         this.startSegment = wpIndex - 1
        //         this.finishSegment = wpIndex + 1
        //         this.calculateRoute(wpIndex - 1, wpIndex + 1)
        //     }
        // })

        eventBus.$off('show-from-search')
        eventBus.$on('show-from-search', (payload) => {
            this.map.setView([payload.lat, payload.lon], 8);
        })

        this.map.on('click', (e) => {
            if (isDrawStarted)
                console.log('click in draw')
            else
                console.log('click')
        })
        this.map.on('mousedown', (e) => {
            if (isDrawStarted)
                console.log('mousedown in draw')
            else
                console.log('mousedown')
        })

        this.map.on('click', (e) => {

            if (isMeasureStarted)
                return

            if (isDrawStarted) {
                console.log("in draw")
            } else {
                console.log("not in draw")
                let content
                let coordinates = e.latlng
                // e_listener = e
                this.map.closePopup()
                if (popup) {
                    popup.removeFrom(this.map)
                }

                // alert(this.is_autorouting)

                // if (this.is_autorouting) {
                //     if (this.autorouting_points === 0) {
                //         let id1 = 'start-wp' + i;
                //         content = `<button id="` + id1 + `" onclick="">Start point</button>`
                //         L.popup().setLatLng(e.latlng).setContent(content).openOn(this.map);
                //         document.getElementById(id1).addEventListener('click', (e) => {
                //             this.map.closePopup()
                //             this.addWP(coordinates)
                //             this.autorouting_points++
                //         });
                //         i++
                //     } else if (this.autorouting_points === 1) {
                //         let id1 = 'finish-wp' + i;
                //         content = `<button id="` + id1 + `" onclick="">Finish point</button>`
                //         L.popup().setLatLng(e.latlng).setContent(content).openOn(this.map);
                //         document.getElementById(id1).addEventListener('click', (e) => {
                //             this.map.closePopup()
                //             this.addWP(coordinates)
                //             this.autorouting_points++
                //             this.calculateRoute()
                //         });
                //         i++
                //     } else if (this.autorouting_points === 2) {
                //         let id1 = 'new-autoroute' + i;
                //         content = `<button id="` + id1 + `" onclick="">Start point</button>`
                //         L.popup().setLatLng(e.latlng).setContent(content).openOn(this.map);
                //         document.getElementById(id1).addEventListener('click', (e) => {
                //             this.map.closePopup()
                //             this.autorouting_points = 0
                //             this.removeRoute()
                //             store.commit("routes/resetStorage")
                //             this.addWP(coordinates)
                //             this.autorouting_points++
                //         });
                //         i++
                //     }
                // } else {
                    if (this.wpMarkers.length === 0) {
                        let id0 = 'start-wp' + i;
                        let id1 = 'add-wp' + i;
                        content = `<button id="` + id0 + `" class="button-13" onclick="">Start</button>&nbsp;<button id="` + id1 + `" class="button-13" onclick="">Add</button>`
                        popup = L.popup({autoClose: false}).setLatLng(e.latlng).setContent(content).openOn(this.map);
                        document.getElementById(id0).addEventListener('click', (e) => {
                            this.map.closePopup()
                            this.is_autorouting = true
                            this.addWP(coordinates)
                            this.autorouting_points++
                        })
                        document.getElementById(id1).addEventListener('click', (e) => {
                            this.map.closePopup()
                            this.addWP(coordinates, -1, false, true)
                            this.drawLegs()
                        })
                        i++
                    } else {
                        let id0 = 'finish-wp' + i;
                        let id1 = 'add-wp' + i;
                        let id2 = 'insert-wp' + i;
                        // let id3 = 'clear-all' + i;
                        // let id4 = 'recalculate';
                        // let id5 = 'safety';

                        // content = `<button id="` + id0 + `" class="button-13" onclick="">Start</button>&nbsp;
                        //             <button id="` + id1 + `" class="button-13" onclick="">Add</button>&nbsp;
                        //             <button id="` + id2 + `" class="button-13" onclick="">Insert</button>`

                        content = `<button id="` + id1 + `" class="button-13" onclick="">Add</button>&nbsp;<button id="` + id2 + `" class="button-13" onclick="">Insert</button>`

                        if (this.autorouting_points > 0)
                            content = `<button id="` + id0 + `" class="button-13" onclick="">Finish</button>
                            </button>`



                        // if (this.wpMarkers.length > 1) {
                        //     content = `<button id="` + id1 + `" onclick="">Add</button>
                        //             <button id="` + id2 + `" onclick="">Insert</button>`
                        //             // <button id="` + id3 + `" onclick="">Clear</button>
                        //             // <button id="` + id4 + `" onclick="">Recalculate</button>
                        //             // <button id="` + id5 + `" onclick="">Safety</button>`
                        // }

                        L.popup({autoClose: false}).setLatLng(e.latlng).setContent(content).openOn(this.map);

                        if (this.autorouting_points > 0) {
                            document.getElementById(id0).addEventListener('click', (e) => {
                                this.map.closePopup()
                                this.addWP(coordinates)
                                this.autorouting_points++
                                this.calculateRoute()
                            })
                        }
                        document.getElementById(id1).addEventListener('click', (e) => {
                            this.map.closePopup()
                            this.addWP(coordinates, -1, false, true)
                            this.drawLegs()
                            setTimeout(() => {
                                eventBus.$emit('check-safety')
                            }, 500);
                        })
                        document.getElementById(id2).addEventListener('click', (e) => {
                            this.map.closePopup()
                            this.insertWP(coordinates)
                            this.drawLegs()
                            setTimeout(() => {
                                eventBus.$emit('check-safety')
                            }, 500);
                        });
                        // document.getElementById(id3).addEventListener('click', (e) => {
                        //     this.map.closePopup()
                        //     this.removeRouteWithDebug()
                        //     this.map.removeControl(this.moveControl)
                        //     eventBus.$emit('show-wp-index1', -1)
                        // });
                        // document.getElementById(id4).addEventListener('click', (e) => {
                        //     this.map.closePopup()
                        //     this.calculateRoute()
                        //     // eventBus.$emit('recalculate_all')
                        // });
                        // document.getElementById(id5).addEventListener('click', (e) => {
                        //     this.map.closePopup()
                        //     this.checkSafety()
                        // });
                        i++

                    }
                }
            // }
        })

        this.wpGroup.on("click", function (e) {
            let clickedMarker = event.layer;
            console.log("a1 ")

        });
        this.wpGroup.on("dragend", function (event) {
            let clickedMarker = event.layer;
            console.log("a2 ")
        });

        eventBus.$on('load-route-data', () => {
            this.loadData()
        })

        eventBus.$on('restore-route', () => {
            let route = store.state.routes.route
            this.removeRoute()

            route.forEach(wp => {
                this.addWP(wp)
                this.drawLegs()
            })
        })

        eventBus.$off('cancel-auto-route')
        eventBus.$on('cancel-auto-route', () => {
            // alert("a1")
            this.cancel_auto_route = true
        })

        eventBus.$off('cancel-weather')
        eventBus.$on('cancel-weather', () => {
            this.setTileLayerWithDraught();
        })

        eventBus.$off('show-weather')
        eventBus.$on('show-weather', () => {
            this.map.setMinZoom(4)
        })

        eventBus.$off('change-nogo')
        eventBus.$on('change-nogo', () => {
            if (prevDraught !== this.routesStore.routeSettings.draught) {
                console.log("new NO GO")
                prevDraught = this.routesStore.routeSettings.draught;
                this.setTileLayerWithDraught();
            }
        })

        eventBus.$off('return-nogo')
        eventBus.$on('return-nogo', () => {
            console.log("return NO GO")
            this.setTileLayerWithDraught();
            this.changeWeatherText("")
        })

        eventBus.$off('change-weather')
        eventBus.$on('change-weather', () => {
            console.log("new WEATHER")
            this.setTileLayerWithWeather()
        })

        eventBus.$off('change-wind')
        eventBus.$on('change-wind', () => {
            console.log("new WIND DATE")
            this.setTileLayerWithWind()
        })

        eventBus.$off('change-temperature')
        eventBus.$on('change-temperature', () => {
            console.log("new TEMPERATURE DATE")
            this.setTileLayerWithTemperature()
        })


        eventBus.$off('wp-selected')
        eventBus.$on('wp-selected', (wp, index = -1) => {
            // this.map.flyTo([wp.lat, wp.lon])

            // setTimeout(function () {
            //     this.map.setZoom(14)
            //     this.map.panTo([wp.lat, wp.lon])
            this.map.setView([wp.lat, wp.lon], 14, {animate: false})
            if (index !== -1)
                eventBus.$emit('click-wp', index, true)


                // const zoom = this.map.getZoom();
                // if (zoom < 14)
                //     this.map.setZoom(14)

                // this.map.invalidateSize(true);
            // }, 100);

            // setTimeout(function () {
            //     this.map.invalidateSize(true);
            // }, 200);
        })

        eventBus.$off('route-changed-from-table')
        eventBus.$on('route-changed-from-table', items => {
            this.pMeasure._toggleMeasure();
            if (this.pMeasure._layerPaint) {
                this.pMeasure._layerPaint.clearLayers();
            }
            this.pMeasure._toggleMeasure();
            this.pMeasure._startLine(items[0]);
            // add subsequent points:
            items.forEach((point, ind) => {
                const latLng = L.latLng(point);
                this.pMeasure._mouseMove({latLng});
                this.pMeasure._currentLine.addPoint(latLng);
                // on last point,
                if (ind === items.length - 1) {
                    this.pMeasure._onKeyDown({keyCode: 27})
                }
            });
            if (items.length > 2) this.updateRoute(items);
            else if (items.length == 2) eventBus.$emit('change-draught', items);
        })

        // eventBus.$off('change-draught1')
        // eventBus.$on('change-draught1', (pts, zones) => {
        //     store.commit("routes/setRoutePointsBackup")
        //
        //     this.setTileLayerWithDraught();
        //     const draught = document.getElementById('sb-inline-draught').defaultValue;
        //     const height = document.getElementById('sb-inline-height').defaultValue;
        //     const radius = document.getElementById('sb-inline-radius').defaultValue;
        //     const xte_l = document.getElementById('sb-inline-xte-l').defaultValue;
        //     const xte_r = document.getElementById('sb-inline-xte-r').defaultValue;
        //     const speed = document.getElementById('sb-inline-speed').defaultValue;
        //     const recommended = document.getElementById('sb-inline-use-recommended').checked;
        //
        //
        //     // get id for route calculation
        //     let url = 'https://nroutes.bgeo.fi:6003/route?startLat=' + pts.lat1 +
        //         '&startLon=' + pts.lng1 +
        //         '&finishLat=' + pts.lat2 +
        //         '&finishLon=' + pts.lng2 +
        //         '&depth=' + draught +
        //         '&height=' + height +
        //         '&radius=' + radius +
        //         '&xteLeft=' + xte_l +
        //         '&xteRight=' + xte_r +
        //         '&speedKnots=' + speed;
        //
        //     store.commit("routes/resetStorage")
        //     eventBus.$emit('route-update-from-map', []);
        //     store.commit("debug/setRouteURL", url)
        //
        //     this.removeRoute()
        //
        //     let post_text = {
        //         useAutoRoute: true,
        //         useRecomendedTracks: recommended,
        //         resctrictedZones: zones,
        //         speedKnots: speed,
        //         points:
        //             [
        //                 {
        //                     lat: pts.lat1,
        //                     lon: pts.lng1,
        //                     radius: radius,
        //                     xteLeft: xte_l,
        //                     xteRight: xte_r
        //                 },
        //                 {
        //                     lat: pts.lat2,
        //                     lon: pts.lng2,
        //                     radius: radius,
        //                     xteLeft: xte_l,
        //                     xteRight: xte_r
        //                 }
        //             ],
        //         depth: draught,
        //         height: height,
        //         defaultXteLeft: xte_l,
        //         defaultXteRight: xte_r,
        //         defaultRadius: radius,
        //         waitSeconds: 0
        //     }
        //
        //     navigator.clipboard.writeText(JSON.stringify(post_text))
        //
        //     myAxios.post('/api/v1/route', post_text)
        //         .then(resp => {
        //             if (resp.data.errorCode === 0 && resp.data.id) {
        //                 store.commit("routes/setCalculatingStatus", true)
        //                 // get route calculation progress and result
        //                 progressCounter = 0;
        //                 prevProgress = -1;
        //                 this.getRoute(resp.data.id).then(routeResponce => {
        //                     console.log(routeResponce.isSuccess)
        //                     if (routeResponce.isSuccess) {
        //                         store.commit("routes/setCalculatingStatus", false)
        //
        //                         const pts = routeResponce.routePoints.slice(1, routeResponce.routePoints.length - 1);
        //
        //                         store.commit("routes/setRoute", {
        //                             route: [],
        //                             length: '',
        //                             dangers: [],
        //                         })
        //
        //                         store.commit("routes/setRoute", {
        //                             route: pts,
        //                             length: routeResponce.routeLength,
        //                             dangers: routeResponce.dangerObjects,
        //                         })
        //                         eventBus.$emit('route-update-from-map', pts);
        //
        //                         if (this.startSegment === 0) {
        //                             pts.forEach((wp, i) => {
        //                                 this.addWP(wp, -1, true)
        //                             })
        //
        //                             store.state.routes.routePrevious.forEach((wp, i) => {
        //                                 if (i > 1) {
        //                                     this.addWP(store.state.routes.routePrevious[i], -1, true)
        //                                 }
        //                             })
        //                         }
        //
        //                         if (this.startSegment !== -1 && this.finishSegment === store.state.routes.routePrevious.length - 1) {
        //                             store.state.routes.routePrevious.forEach((wp, i) => {
        //                                 if (i < this.startSegment) {
        //                                     this.addWP(store.state.routes.routePrevious[i], -1, true)
        //                                 }
        //                             })
        //                             pts.forEach((wp, i) => {
        //                                 this.addWP(wp, -1, true)
        //                             })
        //
        //                         }
        //
        //                         if (this.startSegment === -1 && this.finishSegment === -1) {
        //                             pts.forEach((wp, i) => {
        //                                 this.addWP(wp, -1, true)
        //                             })
        //                         }
        //
        //                         this.drawLegs()
        //                         this.debugLegsGroup.clearLayers()
        //                         eventBus.$emit('toggle-mode-after-auto')
        //
        //                     } else {
        //                         store.commit("routes/setCalculatingStatus", false)
        //
        //                         progressCounter = 0;
        //                         prevProgress = -1;
        //
        //                         this.map.spin(false)
        //
        //                         let points = ':\n\n' + pts.lat1 + ", " + pts.lng1 + ' : \n' + pts.lat2 + ", " + pts.lng2
        //                         showError(routeResponce.errorCode, points)
        //
        //                     }
        //                     this.map.spin(false)
        //                 })
        //
        //             } else {
        //                 this.map.spin(false)
        //                 store.commit("routes/setCalculatingStatus", false)
        //                 alert('Error: (' + resp.data.errorCode + ') Cannot compute route');
        //             }
        //         })
        // })

        // eventBus.$off('change-draught2')
        // eventBus.$on('change-draught2', (pts, zones) => {
        //
        //     store.commit("routes/setRoutePointsBackup")
        //
        //     this.setTileLayerWithDraught();
        //     const draught = document.getElementById('sb-inline-draught').defaultValue;
        //     const height = document.getElementById('sb-inline-height').defaultValue;
        //     const radius = document.getElementById('sb-inline-radius').defaultValue;
        //     const xte_l = document.getElementById('sb-inline-xte-l').defaultValue;
        //     const xte_r = document.getElementById('sb-inline-xte-r').defaultValue;
        //     const speed = document.getElementById('sb-inline-speed').defaultValue;
        //     const recommended = document.getElementById('sb-inline-use-recommended').checked;
        //
        //
        //     store.commit("routes/resetStorage")
        //     eventBus.$emit('route-update-from-map', []);
        //
        //     this.removeRoute()
        //
        //     let pts1, pts2
        //
        //     myAxios.post('/api/v1/route', {
        //         useAutoRoute: true,
        //         useRecomendedTracks: recommended,
        //         resctrictedZones: zones,
        //         speedKnots: speed,
        //         points:
        //             [
        //                 {
        //                     lat: pts.lat1,
        //                     lon: pts.lng1,
        //                     radius: radius,
        //                     xteLeft: xte_l,
        //                     xteRight: xte_r
        //                 },
        //                 {
        //                     lat: pts.lat2,
        //                     lon: pts.lng2,
        //                     radius: radius,
        //                     xteLeft: xte_l,
        //                     xteRight: xte_r
        //                 }
        //             ],
        //         depth: draught,
        //         height: height,
        //         defaultXteLeft: xte_l,
        //         defaultXteRight: xte_r,
        //         defaultRadius: radius,
        //         waitSeconds: 0
        //     })
        //         .then(resp => {
        //             if (resp.data.errorCode === 0 && resp.data.id) {
        //                 store.commit("routes/setCalculatingStatus", true)
        //                 // get route calculation progress and result
        //                 progressCounter = 0;
        //                 prevProgress = -1;
        //                 this.getRoute(resp.data.id).then(routeResponce => {
        //                     console.log(routeResponce.isSuccess)
        //                     if (routeResponce.isSuccess) {
        //                         store.commit("routes/setCalculatingStatus", false)
        //
        //                         pts1 = routeResponce.routePoints.slice(1, routeResponce.routePoints.length - 1);
        //
        //                         myAxios.post('/api/v1/route', {
        //                             useAutoRoute: true,
        //                             useRecomendedTracks: recommended,
        //                             resctrictedZones: zones,
        //                             speedKnots: speed,
        //                             points:
        //                                 [
        //                                     {
        //                                         lat: pts.lat2,
        //                                         lon: pts.lng2,
        //                                         radius: radius,
        //                                         xteLeft: xte_l,
        //                                         xteRight: xte_r
        //                                     },
        //                                     {
        //                                         lat: pts.lat3,
        //                                         lon: pts.lng3,
        //                                         radius: radius,
        //                                         xteLeft: xte_l,
        //                                         xteRight: xte_r
        //                                     }
        //                                 ],
        //                             depth: draught,
        //                             height: height,
        //                             defaultXteLeft: xte_l,
        //                             defaultXteRight: xte_r,
        //                             defaultRadius: radius,
        //                             waitSeconds: 0
        //                         })
        //                             .then(resp => {
        //                                 if (resp.data.errorCode === 0 && resp.data.id) {
        //                                     store.commit("routes/setCalculatingStatus", true)
        //                                     // get route calculation progress and result
        //                                     progressCounter = 0;
        //                                     prevProgress = -1;
        //                                     this.getRoute(resp.data.id).then(routeResponce => {
        //                                         console.log(routeResponce.isSuccess)
        //                                         if (routeResponce.isSuccess) {
        //                                             store.commit("routes/setCalculatingStatus", false)
        //
        //                                             pts2 = routeResponce.routePoints.slice(1, routeResponce.routePoints.length - 1);
        //
        //                                             store.state.routes.routePrevious.forEach((wp, i) => {
        //                                                 if (i < startSegment) {
        //                                                     this.addWP(store.state.routes.routePrevious[i], -1, true)
        //                                                 }
        //                                             })
        //
        //                                             pts1.forEach((wp, i) => {
        //                                                 this.addWP(wp, -1, true)
        //                                             })
        //
        //                                             pts2.forEach((wp, i) => {
        //                                                 if (i !== 0)
        //                                                     this.addWP(wp, -1, true)
        //                                             })
        //
        //                                             store.state.routes.routePrevious.forEach((wp, i) => {
        //                                                 if (i > finishSegment) {
        //                                                     this.addWP(store.state.routes.routePrevious[i], -1, true)
        //                                                 }
        //                                             })
        //
        //
        //                                             this.drawLegs()
        //                                             this.debugLegsGroup.clearLayers()
        //                                             eventBus.$emit('toggle-mode-after-auto')
        //
        //                                         } else {
        //                                             store.commit("routes/setCalculatingStatus", false)
        //
        //                                             progressCounter = 0;
        //                                             prevProgress = -1;
        //
        //                                             this.map.spin(false)
        //                                             store.commit("routes/setCalculatingStatus", false)
        //
        //                                             let points = ':\n\n' + pts1.lat1 + ", " + pts1.lng1 + ' : \n' + pts2.lat2 + ", " + pts2.lng2
        //                                             showError(routeResponce.errorCode, points)
        //
        //                                         }
        //                                         this.map.spin(false)
        //                                     })
        //
        //                                 } else {
        //                                     this.map.spin(false)
        //                                     store.commit("routes/setCalculatingStatus", false)
        //                                     store.commit("routes/setCalculatingStatus", false)
        //                                     alert('Error: (' + resp.data.errorCode + ') Cannot compute route');
        //                                 }
        //                             })
        //
        //                     } else {
        //                         store.commit("routes/setCalculatingStatus", false)
        //
        //                         progressCounter = 0;
        //                         prevProgress = -1;
        //
        //                         this.map.spin(false)
        //                         store.commit("routes/setCalculatingStatus", false)
        //
        //                         if (routeResponce.errorCode === 3) {
        //                             alert('Error: (' + routeResponce.errorCode + ') Start point in a wrong area');
        //                         } else if (routeResponce.errorCode === 4) {
        //                             alert('Error: (' + routeResponce.errorCode + ') Finish point in a wrong area');
        //                         } else if (routeResponce.errorCode === -3) {
        //                             alert('Error: (' + routeResponce.errorCode + ') Can\'t compute the route');
        //                         } else if (this.cancel_auto_route) {
        //                             this.cancel_auto_route = false
        //                         } else {
        //                             alert('Error: (' + routeResponce.errorCode + ') Cannot compute route with these coordinates');
        //                         }
        //                     }
        //                     this.map.spin(false)
        //                 })
        //
        //             } else {
        //                 this.map.spin(false)
        //                 store.commit("routes/setCalculatingStatus", false)
        //                 alert('Error: (' + resp.data.errorCode + ') Cannot compute route');
        //             }
        //         })
        // })

        // eventBus.$off('toggle-mode-after-auto')
        // eventBus.$on('toggle-mode-after-auto', () => {
        //     this.is_autorouting = false
        //
        //     this.startSegment = -1
        //     this.finishSegment = -1
        //
        //     this.autorouting_points = 0
        //     let autoWPMarkers = this.wpMarkers
        //     this.removeRoute()
        //     store.commit("routes/resetStorage")
        //     autoWPMarkers.forEach(wp => {
        //         this.addWP(wp.getLatLng())
        //     })
        //     this.drawLegs()
        //
        //     this.map.removeControl(this.autorouteControl)
        //     this.map.removeControl(this.upButton)
        //     this.map.removeControl(this.downButton)
        //
        //     this.autorouteControl.addTo(this.map)
        //     this.downButton.addTo(this.map)
        //     this.upButton.addTo(this.map)
        // })

        // eventBus.$off('show-move-control')
        // eventBus.$on('show-move-control', (index) => {
        //     this.moveControl.addTo(this.map)
        //     this.movingWP = this.wpMarkers[index]
        // })

        eventBus.$off('move-wp-start')
        eventBus.$on('move-wp-start', index => {
            // this.map.removeControl(this.wpControl)
            // this.map.removeControl(this.autorouteControl)
            this.moveControl.addTo(this.map)
            let wp = this.wpMarkers[index]
            // this.movingWP = wp
            store.commit("routes/setMovingWP", index)

            this.wpMarkers[store.state.routes.movingWP].setIcon(this.wpIconRed)

            this.movingWPBearing = -1

            if (this.wpMarkers.length > wp.options.id + 1) {

                this.movingLeg = [wp, this.wpMarkers[wp.options.id + 1]]

                let c0 = wp.getLatLng()
                let c1 = this.wpMarkers[wp.options.id + 1].getLatLng()
                let angle = GeometryUtil.bearing(c0, c1)
                console.log("aaa", c0, c1)
                console.log("aaa", angle)
                // this.movingWPBearing = angle
                this.movingWPBearing = -1
            }
        })

        eventBus.$off('move-wp-finish')
        eventBus.$on('move-wp-finish', () => {
            this.map.removeControl(this.moveControl)

            this.wpMarkers[store.state.routes.movingWP].setIcon(this.wpIconDefault)

            // this.movingWP = null
            store.commit("routes/setMovingWP", -1)
            this.movingLeg = []
            // this.autorouteControl.addTo(this.map)
            if (this.wpMarkers.length > 1) {
                this.wpControl.addTo(this.map)
            }
        })

        eventBus.$off('toggle-nogo-edit-mode')
        eventBus.$on('toggle-nogo-edit-mode', state => {
            this.debugLegsGroup.clearLayers()

            // if (state) {
            //     console.log("ggg", nogoList)
            // }


            if (state) {
                this.map.removeControl(this.drawControl)
                isDrawStarted = false

                this.map.removeControl(this.wpControl)
                this.clearControl.addTo(this.map)
                this.safetyControl.addTo(this.map)
                this.safetyWPControl.addTo(this.map)
                this.recalculateControl.addTo(this.map)
                this.recalculateWPControl.addTo(this.map)
                // this.autorouteControl.addTo(this.map)
                this.wpControl.addTo(this.map)
                // this.moveControl.addTo(this.map)
                // this.downButton.addTo(this.map)
                // this.upButton.addTo(this.map)

                if (this.is_autorouting) {
                    eventBus.$emit('toggle-mode-after-edit-zones')
                }

                // zonesLayer.clearLayers()
                nogoList

            } else {
                this.drawControl.addTo(this.map);
                isDrawStarted = true

                // this.map.removeControl(this.autorouteControl)
                this.map.removeControl(this.clearControl)
                this.map.removeControl(this.safetyControl)
                this.map.removeControl(this.safetyWPControl)
                this.map.removeControl(this.recalculateControl)
                this.map.removeControl(this.recalculateWPControl)
                this.map.removeControl(this.wpControl)
                this.map.removeControl(this.moveControl)
                // this.map.removeControl(this.upButton)
                // this.map.removeControl(this.downButton)
            }
        })

        eventBus.$off('toggle-auto-mode')
        eventBus.$on('toggle-auto-mode', state => {

            this.debugLegsGroup.clearLayers()
            this.map.removeControl(this.moveControl)
            eventBus.$emit('show-wp-index1', -1)
            store.commit("routes/setMovingWP", -1)

            this.is_autorouting = !this.is_autorouting

            if (state) {
                this.autorouting_points = 0
                let autoWPMarkers = this.wpMarkers
                this.removeRoute()
                store.commit("routes/resetStorage")
                autoWPMarkers.forEach(wp => {
                    this.addWP(wp.getLatLng())
                })
                this.drawLegs()
            } else {
                this.removeRoute()
                store.commit("routes/resetStorage")
            }
        })

        // eventBus.$off('change-draught')
        // eventBus.$on('change-draught', (pts) => {
        //     this.setTileLayerWithDraught();
        //     const currentLine = this.pMeasure._currentLine ? this.pMeasure._currentLine : this.pMeasure._arrPolylines[0];
        //     console.log(this.pMeasure)
        //     this.clearRouteDrawings();
        //     if (currentLine.circleCoords.length > 1) {
        //         const start = currentLine.circleCoords[0];
        //         const finish = currentLine.circleCoords[currentLine.circleCoords.length - 1];
        //         const draught = document.getElementById('sb-inline-draught').defaultValue;
        //         const height = document.getElementById('sb-inline-height').defaultValue;
        //         const radius = document.getElementById('sb-inline-radius').defaultValue;
        //         const xte_l = document.getElementById('sb-inline-xte-l').defaultValue;
        //         const xte_r = document.getElementById('sb-inline-xte-r').defaultValue;
        //         const recommended = document.getElementById('sb-inline-use-recommended').defaultValue;
        //
        //         console.log(draught)
        //         console.log(start, finish)
        //         this.pMeasure._toggleMeasure();
        //         if (this.pMeasure._layerPaint) {
        //             this.pMeasure._layerPaint.clearLayers();
        //         }
        //
        //         // get id for route calculation
        //         let url = 'https://nroutes.bgeo.fi:6003/route?startLat=' + start.lat +
        //             '&startLon=' + start.lng +
        //             '&finishLat=' + finish.lat +
        //             '&finishLon=' + finish.lng +
        //             '&depth=' + draught +
        //             '&height=' + height +
        //             '&radius=' + radius +
        //             '&xteLeft=' + xte_l +
        //             '&xteRight=' + xte_r;
        //
        //         store.commit("routes/resetStorage")
        //         eventBus.$emit('route-update-from-map', []);
        //         store.commit("debug/setRouteURL", url)
        //         myAxios.post('/api/v1/route', {
        //             useAutoRoute: true,
        //             points: pts ?
        //                 [
        //                     {
        //                         lat: pts[0].lat,
        //                         lon: pts[0].lon,
        //                         radius: pts[0].radius,
        //                         xteLeft: pts[0].xteLeft,
        //                         xteRight: pts[0].xteRight
        //                     },
        //                     {
        //                         lat: pts[1].lat,
        //                         lon: pts[1].lon,
        //                         radius: pts[1].radius,
        //                         xteLeft: pts[1].xteLeft,
        //                         xteRight: pts[1].xteRight
        //                     }
        //                 ]
        //                 :
        //                 [
        //                     {
        //                         lat: start.lat,
        //                         lon: start.lng,
        //                         radius: radius,
        //                         xteLeft: xte_l,
        //                         xteRight: xte_r
        //                     },
        //                     {
        //                         lat: finish.lat,
        //                         lon: finish.lng,
        //                         radius: radius,
        //                         xteLeft: xte_l,
        //                         xteRight: xte_r
        //                     }
        //                 ],
        //             depth: draught,
        //             height: height,
        //             defaultXteLeft: xte_l,
        //             defaultXteRight: xte_r,
        //             defaultRadius: radius,
        //             waitSeconds: 0
        //         })
        //             .then(resp => {
        //                 console.log(resp)
        //                 if (resp.data.errorCode === 0 && resp.data.id) {
        //                     store.commit("routes/setCalculatingStatus", true)
        //                     // get route calculation progress and result
        //                     progressCounter = 0;
        //                     prevProgress = -1;
        //                     this.getRoute(resp.data.id).then(routeResponce => {
        //                         console.log(routeResponce.isSuccess)
        //                         if (routeResponce.isSuccess) {
        //                             store.commit("routes/setCalculatingStatus", false)
        //
        //                             const pts = routeResponce.routePoints.slice(1, routeResponce.routePoints.length - 1);
        //                             store.commit("routes/setRoute", {
        //                                 route: [],
        //                                 length: '',
        //                                 dangers: [],
        //                             })
        //                             store.commit("routes/setRoute", {
        //                                 route: pts,
        //                                 length: routeResponce.routeLength,
        //                                 dangers: routeResponce.dangerObjects,
        //                             })
        //                             eventBus.$emit('route-update-from-map', pts);
        //
        //
        //                             console.log(pts)
        //
        //                             this.drawCorridors(pts, xte_l, xte_r);
        //                             routeResponce.dangerObjects.forEach(obj => {
        //                                 this.setDangerMarkers(obj.points, obj.isDanger ? require("../../assets/marker-danger.png") : require("../../assets/marker-warning.png"));
        //                             })
        //                             this.updateRoute(this.pMeasure._currentLine);
        //                             // alert(2)
        //                         } else {
        //                             store.commit("routes/setCalculatingStatus", false)
        //
        //                             progressCounter = 0;
        //                             prevProgress = -1;
        //
        //                             if (routeResponce.errorCode === 4) {
        //                                 alert('Error: (' + routeResponce.errorCode + ') Finish point in a dry land');
        //                             } else {
        //                                 alert('Error: (' + routeResponce.errorCode + ') Cannot compute route with these coordinates');
        //                             }
        //                         }
        //                     })
        //
        //                 } else {
        //                     store.commit("routes/setCalculatingStatus", false)
        //                     alert('Error: (' + resp.data.errorCode + ') Cannot compute route');
        //                 }
        //             })
        //     }
        // })

        this.map.on('zoomend', () => {

            const zoom = this.map.getZoom();
            // this.dangerMarkers.remove();
            // this.warningMarkers.remove();

            this.map.removeLayer(this.dangerMarkers);
            this.map.removeLayer(this.warningMarkers);
            // this.map.removeLayer(this.wpGroup)

            if (this.isZonesTab) {
                this.map.removeLayer(allObjectsPolyline)
                this.map.removeLayer(objectsPolyline)
            }

            if (zoom >= 8 && this.isZonesTab) {
                this.map.addLayer(allObjectsPolyline)
                this.map.addLayer(objectsPolyline)
            }

            if (zoom >= 14) {
                this.map.addLayer(this.dangerMarkers)
                this.map.addLayer(this.warningMarkers)
                // this.map.addLayer(this.wpGroup)
                // this.warningMarkers.addTo(this.markerLayer);
                // this.dangerMarkers.addTo(this.markerLayer);
            }
        })
    }

    updateRoute(pts) {
        alert('m1')
        const draught = document.getElementById('sb-inline-draught').value;
        const height = document.getElementById('sb-inline-height').value;
        const radius = document.getElementById('sb-inline-radius').value;
        const xte_l = document.getElementById('sb-inline-xte-l').value;
        const xte_r = document.getElementById('sb-inline-xte-r').value;
        const recommended = document.getElementById('sb-inline-use-recommended').checked;
        const ratio = document.getElementById('sb-inline-ratio').value;
        const speed = document.getElementById('sb-inline-speed').value;
        const shallowratio = document.getElementById('sb-inline-shallowratio').value;
        const shallowstrength = document.getElementById('sb-inline-shallowstrength').value;
        const avoiddistance = document.getElementById('sb-inline-avoiddistance').value;
        const usesuez = document.getElementById('sb-inline-use-suez').value;
        const usepanama = document.getElementById('sb-inline-use-panama').value;
        const usebering = document.getElementById('sb-inline-use-bering').value;
        const userivers = document.getElementById('sb-inline-use-rivers').value;
        let points = []

        if (!pts) {
            const currentLine = this.pMeasure._currentLine ? this.pMeasure._currentLine : this.pMeasure._arrPolylines[0];

            currentLine.circleCoords.forEach(pt => {
                points.push({
                    lat: pt.lat, lon: pt.lng, radius: radius,
                    xteLeft: xte_l,
                    xteRight: xte_r
                })
            })
        } else {
            pts.forEach(pt => {
                points.push({
                    lat: pt.lat, lon: pt.lon, radius: pt.radius,
                    xteLeft: pt.xteLeft,
                    xteRight: pt.xteRight
                })
            })
        }

        store.commit("debug/setRouteUpdateURL", {
            url: 'https://nroutes.bgeo.fi:6003/updateroute', data: {
                points: points,
                depth: draught,
                height: height,
                radius: radius,
                xteLeft: xte_l,
                xteRight: xte_r
            }
        });

        this.clearRouteDrawings();
        eventBus.$emit('route-update-from-map', points);
        myAxios.post('/api/v1/route',
            {
                points: points,
                depth: draught,
                height: height,
                defaultXteLeft: xte_l,
                defaultXteRight: xte_r,
                defaultRadius: radius,
                useAutoRoute: false,
            }).then(resp => {


            // store.commit("debug/setRouteResponce", resp)

            if (resp.data.isSuccess) {
                const pts = resp.data.routePoints;
                const ptsInfos = resp.data.routePointInfos;
                let newpts = [];
                for (let i = 0; i < pts.length; i++) {
                    const pt = pts[i]
                    const pti = ptsInfos[i]
                    newpts.push({lat: pt.lat, lon: pt.lon, course: pti.course});
                }
                store.commit("routes/setRoute", {
                    route: [],
                    length: '',
                    dangers: [],
                })


                store.commit("routes/setRoute", {
                    route: pts,
                    length: resp.data.routeLength,
                    dangers: resp.data.dangerObjects,
                })

                console.log("eeee", resp.data)

                // pts.forEach((coordinates, i) => {
                //     let lat = coordinates.lat
                //     let lon = coordinates.lon
                //
                //     let startdate = this.$store.state.routes.route_settings.date
                //     let starttime = this.$store.state.routes.route_settings.time
                //     let seconds = this.$store.state.routes.info[i].fromStartSeconds
                //     let datetime = moment(startdate + " " + starttime, "YYYY-MM-DD HH:mm")
                //     let datetimewp = datetime.add(seconds, "seconds").unix()
                //     console.log("jjj", i, lat, lon, datetime)
                // })



                // resp.data.dangerObjects.forEach(obj => {
                //     this.setDangerMarkers(obj.points, obj.isDanger ? "danger" : "warning")
                // })
                const ptsDashLeft = this.dashPolyline(newpts, 'left', xte_l / 2);
                const ptsDashRight = this.dashPolyline(newpts, "right", xte_r / 2);
                this.drawCorridors(ptsDashLeft, xte_l, "white");
                this.drawCorridors(ptsDashRight, xte_r, "red");
            }
        })
    }

    setTileLayerWithWeather() {
        if (this.navalLayer) this.navalLayer.remove();

        let type = store.state.fickle.weather.showWind ? "wind" : "temp"
        const url = "https://nogotiles.bgeo.fi:6011/" + type + "/{z}/{x}/{y}?t=" + store.state.fickle.weather.datetime;
        // this.changeWeatherText(moment.unix(store.state.fickle.weather.datetime).format("DD MMM HH:mm"))
        this.changeWeatherText(dayjs.unix(store.state.fickle.weather.datetime).format('DD MMM HH:mm'))

        console.log(url);
        this.map.setMinZoom(4)
        this.navalLayer = L.tileLayer(url, {
            minZoom: 4,
            maxZoom: 20,
        }).addTo(this.map)
    }

    // setTileLayerWithWind() {
    //     alert(1)
    //     if (this.navalLayer) this.navalLayer.remove();
    //     const url = "https://nogotiles.bgeo.fi:6011/wind/{z}/{x}/{y}?t=" + store.state.fickle.weather.datetime;
    //     console.log(url);
    //     this.navalLayer = L.tileLayer(url, {
    //         minZoom: 2,
    //         maxZoom: 20,
    //     }).addTo(this.map)
    // }
    //
    // setTileLayerWithTemperature() {
    //     alert(2)
    //     if (this.navalLayer) this.navalLayer.remove();
    //     const url = "https://nogotiles.bgeo.fi:6011/temp/{z}/{x}/{y}?t=" + store.state.fickle.weather.datetime;
    //     console.log(url);
    //     this.navalLayer = L.tileLayer(url, {
    //         minZoom: 2,
    //         maxZoom: 20,
    //     }).addTo(this.map)
    // }


    setTileLayerWithDraught() {
        if (this.navalLayer) this.navalLayer.remove();
        const draught = this.routesStore.routeSettings.draught;

        const url = "https://nogotiles.bgeo.fi:6007/nogo/{z}/{x}/{y}?depth=" + draught;
        // const url = "https://nogotiles.bgeo.fi:6011/wind/9/264/155?t=1708441200";
        console.log(url);
        this.map.setMinZoom(2)
        this.navalLayer = L.tileLayer(url, {
            minZoom: 8,
            maxZoom: 20,
        }).addTo(this.map)
    }

    drawCorridors(route, width, color = 'white') {
        console.log("aaa", route)

        let color1 = color === 'white' ? 'yellow' : color

        if (color === 'yellow')
            this.corridorLayer3.clearLayers();
        if (color === 'red')
            this.corridorLayer2.clearLayers();
        if (color === 'white')
            this.corridorLayer1.clearLayers();
        let coords = [];
        const options = {
            corridor: width, // meters
            // className: 'route-corridor'
            opacity: color === 'white' ? 0.3 : 0.3,
            lineCap: "butt",
            fill: false,
            pane: "corridor",
            interactive: false,
            color: color1,
            noClip: true,
            lineJoin: "bevel"
        };
        let dangers1 = store.state.routes.dangers_legs
        let warnings = store.state.routes.warning_legs

        let dangers = this.routesStore.routeLegs

        let legIndex = 0
        route.forEach((pt, i) => {
            let safety = dangers[legIndex - 1]?.safety ?? 1
            if (pt) {
                coords.push(L.latLng(pt.lat, pt.lon ? pt.lon : pt.lng))
                if (color === 'red' && coords.length === 2) {
                    legIndex++
                    if (safety === 2)
                        this.corridor = L.corridor(coords, options).addTo(this.corridorLayer2);

                    // if (warnings[legIndex] === true)
                    //     this.corridor = L.corridor(coords, options).addTo(this.corridorLayer3);

                    coords.splice(0, 1)
                }
            }
        })
        if (color !== 'red')
            this.corridor = L.corridor(coords, options).addTo(this.corridorLayer1);
    }

    setDangerMarkers(points, type) {

        let icon = type == "danger" ? "<div style='font-size: 20px'>&#128315;</div>" : "<div class='yellow'>&#128315;</div>"
        // let icon = type == "danger" ? "<div style='color: #e5d60f'>&#128315;</div>" : "<img src='./marker-warning.png'/>"
        let markerDanger = L.divIcon({
            className: '',
            iconSize: [20, 20],
            iconAnchor: [10, 10],
            html: icon   // best results if iconSize = font-size = line-height and iconAnchor font-size/2 .both values needed to position symbol in center of L.divIcon for all font-sizes.
        });

        points.forEach(pt => {
            let marker = L.marker([pt.lat, pt.lon], {icon: markerDanger, interactive: false});
            marker.addTo(type == 'danger' ? this.dangerMarkers : this.warningMarkers);
        })
    }

    clearRouteDrawings() {
        this.corridorLayer.clearLayers();
        this.dangerMarkers.clearLayers();
        this.warningMarkers.clearLayers();
    }

    dashPolyline(origPoints, dir = 'right', dist) {
        let newPoints = []
        const bearing = dir == "right" ? 90 : -90;
        for (let i = 0; i < origPoints.length; i++) {
            const pt = origPoints[i]
            let courseDeg
            if (i == origPoints.length - 1) courseDeg = toDeg(pt.course);
            else courseDeg = toDeg(origPoints[i + 1].course)

            newPoints.push(destVincenty(pt.lat, pt.lon ? pt.lon : pt.lng, bearing + courseDeg, dist))
        }
        console.log(origPoints, newPoints);
        return newPoints;
    }
}
